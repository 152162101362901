import React, { useState } from 'react';
import { Button } from 'antd';
import web from '../Images/web.png';
import cloud from '../Images/cloud.png';
import ai from '../Images/ai-ml.png';
import data from '../Images/data.png';
import marketing from '../Images/marketing.png';
import webActive from '../Images/web-active.png';
import cloudActive from '../Images/cloud-active.png';
import aiActive from '../Images/ai-ml-active.png';
import dataActive from '../Images/data-active.png';
import marketingActive from '../Images/marketing-active.png';
import point from '../Images/Group 10.png';
import { useNavigate } from 'react-router-dom';

const TechStack = () => {
  const [activeButton, setActiveButton] = useState('web');
  const [activeButtonData, setActiveButtonData] = useState(0);
  const displayData = [
    {
      title: 'Website Development',
      des: "Elevate your online presence with Shivay Intelligence's bespoke website development services. Merging aesthetics with functionality, our team crafts responsive, user-centric websites that embody your brand and engage your audience. Whether you're looking to establish your digital footprint or redefine it, we provide comprehensive web solutions that cater to your unique needs.",
      details: [
        'Web Development & Consultation',
        'Custom Web Apps & Sites Development',
        'E - commerce Website',
        'Digital Modernization',
        'WordPress Solutions',
        'Shopify for ECommerce',
      ],
    },
    {
      title: 'Cloud Consultancy',
      des: 'Navigate the cloud with confidence as Shivay Intelligence guides you through optimizing your IT infrastructure. Our cloud consultancy services are designed to streamline your operations, maximize efficiency, and enhance security in the cloud environment, ensuring that your business is agile, scalable, and future-proof.',
      details: [
        'Cloud Strategy and Architecture',
        'Deployment and Migration Services',
        'Cloud Security and Compliance',
        'Managed Cloud Services',
        'Cloud Optimization and Cost Management',
        'Disaster Recovery Planning',
      ],
    },
    {
      title: 'AI / ML',
      des: 'Unlock the potential of artificial intelligence with Shivay Intelligence. We harness the power of AI and ML to provide intelligent solutions that automate processes, deliver insights, and drive innovation. Our AI/ML services help you stay ahead in a rapidly evolving tech landscape.',
      details: [
        'Machine Learning Model Development',
        'Recommendation Engine Creation',
        'AI-powered Process Automation',
        'Predictive Analytics',
        'Natural Language Processing (NLP)',
        'Computer Vision Applications',
      ],
    },
    {
      title: 'Data Management and Analysis',
      des: "Transform data into strategic assets with Shivay Intelligence's data management and analysis services. We specialize in collecting, organizing, and interpreting vast datasets to provide actionable insights that fuel data-driven decision-making and propel business growth.",
      details: [
        'Big Data Processing and Analytics',
        'Real-Time Data Insights',
        'Data Warehousing and ETL Services',
        'Business Intelligence and Reporting',
        'Data Governance and Quality',
        'Advanced Analytics Solutions',
      ],
    },
    {
      title: 'Automated Digital Marketing',
      des: "Elevate your brand's visibility and engagement with Shivay Intelligence's comprehensive digital advertising and SEO services. Harness our mastery in orchestrating impactful ads across Google, Bing, Meta, and more, while fine-tuning your online presence with our expert SEO strategies.",
      details: [
        'Strategic Digital Advertising',
        'Multichannel Ad Placement',
        'SEO and SEM Mastery',
        'CRM-Enhanced Targeting',
        'Tailored Email Marketing',
        'Insightful Social Media Analytics',
      ],
    },
  ];

  const techStack = [
    {
      name: 'web',
      image: web,
      activeImg: webActive,
    },
    {
      name: 'cloud',
      image: cloud,
      activeImg: cloudActive,
    },
    {
      name: 'ai',
      image: ai,
      activeImg: aiActive,
    },
    {
      name: 'data',
      image: data,
      activeImg: dataActive,
    },
    {
      name: 'marketing',
      image: marketing,
      activeImg: marketingActive,
    },
  ];

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/contact-us');
  };

  return (
    <div className="md:mt-5 md:py-5 mt-3 py-3">
      <div className="container-xxl">
        <div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="text-[32px] font-poppins font-[700] mb-5 text-[#0A0240]">Tech Stack</div>
            <div className="text-[18px] font-open-sana font-[700] text-[##0A0240] text-center mb-5">
              Our proficiency in delivering comprehensive AI-powered solutions, paired with sleek UI and scalable backend, efficient data pipelines, and extensive automation sets
              us apart from the competition.
            </div>
          </div>
          <div className="grid container-xxl grid-cols-2 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-3 mb-5 gap-5">
            {techStack.map((item, index) => {
              return (
                <div key={index} className={`flex justify-center items-center ${techStack.length % 2 !== 0 && index == techStack.length - 1 ? 'col-span-2 sm:col-span-1' : ''}`}>
                  <Button
                    type="text"
                    onClick={() => {
                      setActiveButton(item.name);
                      setActiveButtonData(index);
                    }}
                    style={{ height: 150 }}
                  >
                    <img src={activeButton === item.name ? item.activeImg : item.image} alt="" className='min-w-[90px]'/>
                  </Button>
                </div>
              );
            })}
          </div>
          <div className="mt-5 p-5 techStackContentBox" style={{ backgroundColor: '#E5EFFD', borderRadius: '35px' }}>
            <div className="text-[24px] font-[700] font-poppins mb-3">{displayData[activeButtonData].title}</div>
            <p className="text-[18px] font-[400] font-open-sana">{displayData[activeButtonData].des}</p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2  w-full md:w-3/4 m-auto mt-5">
              {displayData[activeButtonData]['details'].map((item, index) => {
                return (
                  <div className="flex items-center gap-3 my-2" key={index}>
                    <img src={point} alt="" style={{ height: '30px' }} />
                    <div className="text-[18px] font-open-sana font-[400]">{item}</div>
                  </div>
                );
              })}
            </div>
            <div className="mt-5 d-flex justify-content-center align-items-center">
              <Button type="text" className="common-button-2 text-base  fw-bolder" style={{ height: 50, width: 150 }} onClick={handleClick}>
                Let&rsquo;s Connect
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechStack;

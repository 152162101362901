import React from 'react';
import image1 from '../Images/about-us-tech-1.png';
import image2 from '../Images/about-us-tech-2.png';
import image3 from '../Images/about-us-tech-3.png';
import image4 from '../Images/about-us-tech-4.png';
import image5 from '../Images/about-us-tech-5.png';
import image6 from '../Images/about-us-tech-6.png';
import image7 from '../Images/about-us-tech-7.png';
import { Col, Row } from 'antd';

const AboutUsTechStack = () => {
  return (
    <div style={{ background: '#152C5B', padding: '30px' }}>
      <div className=" container-xxl">
        <Row gutter={[16, 16]} justify="space-around">
          <Col xs={12} sm={9} md={8} lg={4} xl={3}>
            <div className="d-flex flex-column  align-items-center" style={{ width: 120, height: 120 }}>
              <img src={image1} alt="" style={{ height: 60 }} />
              <div className="aboutUsTechText align-self-center">Elite Raving Fan Culture</div>
            </div>
          </Col>
          <Col xs={12} sm={9} md={8} lg={4} xl={3}>
            <div className="d-flex flex-column  align-items-center" style={{ width: 120, height: 120 }}>
              <img src={image2} alt="" style={{ height: 60 }} />
              <div className="aboutUsTechText align-self-center">Constructive Communication</div>
            </div>
          </Col>
          <Col xs={12} sm={9} md={8} lg={3} xl={3}>
            <div className="d-flex flex-column  align-items-center" style={{ width: 120, height: 120 }}>
              <img src={image3} alt="" style={{ height: 60 }} />
              <div className="aboutUsTechText align-self-center">Humble & Adaptable</div>
            </div>
          </Col>
          <Col xs={12} sm={9} md={8} lg={3} xl={3}>
            <div className="d-flex flex-column  align-items-center" style={{ width: 120, height: 120 }}>
              <img src={image4} alt="" style={{ height: 60 }} />
              <div className="aboutUsTechText align-self-center">Teach & Promote</div>
            </div>
          </Col>
          <Col xs={12} sm={9} md={8} lg={3} xl={3}>
            <div className="d-flex  flex-column  align-items-center" style={{ width: 120, height: 120 }}>
              <img src={image5} alt="" style={{ height: 60 }} />
              <div className="aboutUsTechText align-self-center">Results Driven</div>
            </div>
          </Col>
          <Col xs={12} sm={9} md={8} lg={3} xl={3}>
            <div className="d-flex flex-column  align-items-center" style={{ width: 120, height: 120 }}>
              <img src={image6} alt="" style={{ height: 60 }} />
              <div className="aboutUsTechText align-self-center">Alignment</div>
            </div>
          </Col>
          <Col xs={12} sm={9} md={8} lg={3} xl={3}>
            <div className="d-flex flex-column  align-items-center" style={{ width: 120, height: 120 }}>
              <img src={image7} alt="" style={{ height: 60 }} />
              <div className="aboutUsTechText align-self-center">Integrity </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutUsTechStack;

import React from "react";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

function RichtextRender({ content }) {
  const renderOptions = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
      [MARKS.CODE]: (text) => (
        <code className="bg-gray-200 p-1 rounded">{text}</code>
      ),
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-3xl font-bold">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="text-2xl font-bold">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="text-xl font-bold">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="text-lg font-bold">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className="text-md font-bold">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className="text-sm font-bold">{children}</h6>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-4">{children}</p>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc list-inside mb-4">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="list-decimal list-inside mb-4">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="mb-2">
          {children.map((child, index) =>
            child.type === "p" ? (
              <span key={index}>{child.props.children}</span>
            ) : (
              child
            )
          )}
        </li>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote className="pl-4 border-l-4 border-gray-400 italic mb-4">
          {children}
        </blockquote>
      ),
      [BLOCKS.HR]: () => <hr className="my-4" />,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        return <img src={file.url} alt={title} className="mb-4" />;
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} className="text-blue-600 hover:underline">
          {children}
        </a>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <a
          href={`/entry/${node.data.target.sys.id}`}
          className="text-blue-600 hover:underline"
        >
          {children}
        </a>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => (
        <div className="embedded-entry">{children}</div>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { title, description } = node.data.target.fields;
        return (
          <div className="embedded-entry bg-gray-100 p-4 mb-4 rounded">
            <h2 className="text-xl font-bold">{title}</h2>
            <p>{description}</p>
          </div>
        );
      },
    },
  };
  return <>{documentToReactComponents(content, renderOptions)}</>;
}

export default RichtextRender;

import anupamImg from '../Images/Client/AnupamTripathi.jpg';
import ruchiImg from '../Images/Client/ruchiImage.png';
import { createClient } from 'contentful';
import screenImg2 from '../Images/tlaScreen.png';
import reactIcon from '../Images/Technologies/react-icon.svg';
import nodeJsIcon from '../Images/Technologies/nodejs-icon.svg';
import djangoIcon from '../Images/Technologies/django-icon.svg';
import lambdaIcon from '../Images/Technologies/lambda-icon.svg';
import flutterIcon from '../Images/Technologies/flutter-icon.svg';
import iosIcon from '../Images/Technologies/ios-icon.svg';
import androidIcon from '../Images/Technologies/android-icon.svg';
import testingIcon from '../Images/Technologies/testing-icon.svg';
import awsIcon from '../Images/Technologies/aws-icon.svg';
import seoIcon from '../Images/Technologies/seo-icon.svg';
import googleAdsIcon from '../Images/Technologies/google-ads-icon.svg';
import bingIcon from '../Images/Technologies/bing-icon.svg';
import socialMediaIcon from '../Images/Technologies/social-media-icon.svg';
import emailMarketingIcon from '../Images/Technologies/email-marketing-icon.svg';
import ppcIcon from '../Images/Technologies/ppc-icon.svg';
import dataEngineeringIcon from '../Images/Technologies/data-engineering.svg';
import dataAnalyticsIcon from '../Images/Technologies/data-analytics.svg';
import dataScienceIcon from '../Images/Technologies/data-science-icon.svg';
import mlOpsIcon from '../Images/Technologies/ml-ops-icon.svg';
import dynamoDbIcon from '../Images/Technologies/dynamoDb-icon.svg';
import mongoDbIcon from '../Images/Technologies/mongo-db-icon.svg';
import rdsIcon from '../Images/Technologies/rds-icon.svg';
import elasticIcon from '../Images/Technologies/elastic-icon.svg';
import wordpressIcon from '../Images/Technologies/wordpress-icon.svg';
import pythonIcon from '../Images/Technologies/python-icon.svg';
import shopifyIcon from '../Images/Technologies/shopify-icon.svg';

export const carouselData = [
  {
    title: 'Anupam Tripathi',
    img: anupamImg,
    subTitle: 'Trinetra LLC',
    text: `Shivay Intelligence excels in web and mobile app development, cloud computing, data management, AI, and API integrations. In one year, they delivered a mobile app, two web apps, five websites, and centralized data analytics, driving client success. Their research-driven approach and innovative solutions make them a highly recommended tech partner.`,
  },
  {
    title: 'Ruchi Shah',
    img: ruchiImg,
    subTitle: 'Humanness.ing',
    text: `We're very pleased with Shivay. Introduced through a strong reference, Shivay's management leads well with a "can be done" attitude. Their diligent team excelled in our website design and development. We look forward to partnering with Shivay for future tech needs.`,
  },
];

export const client = createClient({
  space: '3hlcnclj7zqd',
  accessToken: '0ZxktLa8GRB1Ki1H7GXn1T6q3SiJloulOUFLlFjaClE',
});

export const serviceData = {
  'web-application': {
    section1: {
      title1: 'Comprehensive',
      title2: 'Web Application Development Services',
      title_description:
        'At Shivay Intelligence, we specialize in delivering top-tier web application development services that cater to a wide array of business needs. Utilizing the latest technologies such as React JS, Node JS, WordPress, Python, and Shopify, we build robust, scalable, and dynamic web applications. Our expert team is committed to transforming your ideas into innovative solutions that drive business success and enhance user experience.',
    },
    section2: {
      title: 'Web Application Development',
      title_description: [
        'Shivay Intelligence is a leading provider of web application development services, dedicated to helping businesses achieve their digital transformation goals. With a focus on quality, innovation, and customer satisfaction, we leverage cutting-edge technologies to create web applications that are both powerful and user-friendly. Our team of skilled developers, designers, and strategists work collaboratively to ensure your web application not only meets but exceeds your expectations.',
      ],
      listOfServices: [
        {
          title: 'React JS',
          description:
            'We build dynamic and responsive user interfaces with React JS, ensuring a seamless user experience across all devices. Our React JS applications are known for their performance, flexibility, and scalability.',
          img: reactIcon,
        },
        {
          title: 'Node JS',
          description:
            'Our Node JS development services focus on creating high-performance, scalable backend solutions. We use Node JS to develop robust APIs and real-time applications that can handle large volumes of data efficiently.',
          img: nodeJsIcon,
        },
        {
          title: 'Python Development Services',
          description:
            'Our Python development services are designed to deliver robust and scalable web applications. We use Python for its simplicity and versatility, making it ideal for a wide range of applications, from web development to data analysis and machine learning.',
          img: pythonIcon,
        },
        {
          title: 'Serverless Lambda',
          description:
            'Our expertise in serverless architecture using AWS Lambda allows us to create scalable and cost-effective backend solutions. We develop event-driven applications that reduce operational overhead and enhance performance. With event-driven architecture, our applications scale automatically in response to events, ensuring cost-efficiency by paying only for the compute time used.',
          img: lambdaIcon,
        },
        {
          title: 'Shopify',
          description:
            "We offer comprehensive Shopify development services to help you create an engaging and efficient e-commerce platform. Our team can customize Shopify themes and develop unique features to enhance your online store's functionality and user experience.",
          img: shopifyIcon,
        },
        {
          title: 'WordPress',
          description:
            'For businesses looking for a versatile and user-friendly content management system, we offer WordPress development services. We create custom WordPress themes and plugins tailored to your specific needs, ensuring your website stands out.',
          img: wordpressIcon,
        },
      ],
    },
    section3: {
      list_of_stories: [
        {
          id: 1,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
      ],
    },
    section4: {
      list_of_faq: [
        {
          key: '1',
          question: 'What types of web applications can Shivay Intelligence develop?',
          answer:
            'We develop a wide range of web applications, including e-commerce platforms, content management systems, real-time applications, and enterprise-level solutions. Our expertise spans across various technologies, allowing us to create custom solutions tailored to your specific business needs.',
        },
        {
          key: '2',
          question: `How long does it take to develop a web application?`,
          answer:
            'The development timeline depends on the complexity and requirements of the project. A basic web application may take a few months, while more complex applications with extensive features could take six months or more. We provide a detailed timeline after understanding your project requirements.',
        },
        {
          key: '3',
          question: 'Can you help with the maintenance and updates of my web application?',
          answer:
            'Yes, we offer ongoing maintenance and support services to ensure your web application remains up-to-date, secure, and performs optimally. Our services include regular updates, bug fixes, performance improvements, and feature enhancements.',
        },
        {
          key: '4',
          question: 'How do you ensure the security of the web applications you develop?',
          answer:
            'We follow industry best practices for web application security, including secure coding standards, regular security audits, and implementing advanced security measures such as encryption, authentication, and access control. Our goal is to protect your web application from potential threats and vulnerabilities.',
        },
        {
          key: '5',
          question: 'What is the cost of developing a web application?',
          answer:
            'The cost of developing a web application varies based on factors such as the complexity, features, design requirements, and technologies used. We provide a customized quote based on your specific needs after an initial consultation to understand your project in detail.',
        },
        // Add more FAQs as needed
      ],
    },
  },
  'mobile-application': {
    section1: {
      title1: 'Transform Your Business with',
      title2: 'Mobile Applications',
      title_description:
        'At Shivay Intelligence, we provide comprehensive mobile app development services to help you create innovative and high-performing applications for various platforms. Our expertise spans across multiple technologies and platforms, ensuring that we can meet your specific needs and deliver exceptional results.',
    },
    section2: {
      title: 'Mobile App Development Services',
      title_description: [
        'At Shivay Intelligence, we are committed to delivering top-notch mobile app development services that drive business growth and enhance user experience. Whether you need a cross-platform app with React Native or Flutter, or a native app for iOS or Android, our team has the expertise to bring your vision to life. Contact us today to discuss your mobile app development needs and start your journey towards digital success.',
      ],
      listOfServices: [
        {
          title: 'React Native App Development',
          description:
            "Our React Native development services enable you to build powerful cross-platform mobile applications with a single codebase. This approach not only reduces development time and cost but also ensures a consistent user experience across both iOS and Android devices. We leverage React Native's robust framework to create dynamic and high-performance apps that meet your business objectives.",
          img: reactIcon,
        },
        {
          title: 'Flutter App Development Service',
          description:
            "Flutter's unique capability to create natively compiled applications for mobile, web, and desktop from a single codebase makes it an excellent choice for app development. Our Flutter app development services focus on delivering visually appealing and functionally robust applications. We utilize Flutter's rich set of widgets and tools to build high-quality apps that offer a seamless user experience.",
          img: flutterIcon,
        },
        {
          title: 'iOS App Development Service',
          description:
            "Our iOS app development services are designed to create high-quality, feature-rich applications for Apple devices. We ensure that your app meets Apple's strict guidelines and provides an intuitive and engaging user experience. From initial design to final deployment, our team handles every aspect of iOS app development to deliver a product that stands out in the App Store.",
          img: iosIcon,
        },
        {
          title: 'Android App Development Service',
          description:
            'With a vast user base, Android is a critical platform for any mobile app. Our Android app development services cover all stages of development, from concept to deployment. We build scalable and secure Android apps tailored to your specific needs, ensuring they perform flawlessly across a wide range of devices and operating system versions.',
          img: androidIcon,
        },
        {
          title: 'Flutter App Maintenance and Support',
          description:
            'Keeping your app up-to-date and running smoothly is crucial for maintaining user satisfaction and engagement. Our Flutter app maintenance and support services ensure your app remains functional, secure, and relevant. We provide regular updates, bug fixes, and performance improvements to keep your app in top shape.',
          img: testingIcon,
        },
        {
          title: 'App Testing and Quality Assurance',
          description:
            'Quality assurance is an integral part of our mobile app development process. Our dedicated QA team rigorously tests your app to identify and resolve any issues before launch. We use a combination of manual and automated testing methods to ensure your app performs optimally under various conditions and meets the highest standards of quality.',
          img: testingIcon,
        },
      ],
    },
    section3: {
      list_of_stories: [
        {
          id: 1,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
      ],
    },
    section4: {
      list_of_faq: [
        {
          key: '1',
          question: 'What platforms do you develop mobile apps for?',
          answer:
            'We develop mobile apps for both iOS and Android platforms. Additionally, we offer cross-platform solutions using React Native and Flutter, allowing for a single codebase to run seamlessly on multiple platforms.',
        },
        {
          key: '2',
          question: `How long does it take to develop a mobile app?`,
          answer:
            'The development timeline varies based on the complexity and features of the app. A basic app might take a few months, while more complex apps with extensive features could take six months or more. We provide a detailed timeline after understanding your project requirements.',
        },
        {
          key: '3',
          question: 'Can you help with the maintenance and updates of my mobile app?',
          answer:
            'Yes, we offer ongoing maintenance and support services to ensure your app remains up-to-date, secure, and performs optimally. Our services include regular updates, bug fixes, and performance improvements.',
        },
        {
          key: '4',
          question: 'How do you ensure the quality of the mobile app?',
          answer:
            'We have a dedicated QA team that rigorously tests the app using both manual and automated methods. We ensure that the app performs well under various conditions, is free of bugs, and provides a seamless user experience before it is launched.',
        },
        {
          key: '5',
          question: 'What is the cost of developing a mobile app?',
          answer:
            "The cost of developing a mobile app depends on various factors such as the app's complexity, features, design requirements, and platforms. We provide a customized quote based on your specific needs after an initial consultation.",
        },
        // Add more FAQs as needed
      ],
    },
  },
  cloud: {
    section1: {
      title1: 'Transform Your Business with',
      title2: 'Expert Cloud Services',
      title_description:
        'At Shivay Intelligence, we offer expert cloud services to help your business leverage the power of the cloud for improved scalability, flexibility, and efficiency. Our AWS cloud solutions are designed to meet your specific needs, ensuring robust and secure cloud environments.',
    },
    section2: {
      title: 'Cloud Services',
      title_description: [
        'At Shivay Intelligence, we are dedicated to providing exceptional cloud services that enhance business performance and drive growth. Whether you need seamless AWS cloud deployment or robust and scalable AWS cloud architecture, our team of certified experts has the skills and experience to bring your cloud vision to reality. Contact us today to discuss your cloud service needs and begin your journey towards a more efficient and secure digital infrastructure.',
      ],
      listOfServices: [
        {
          title: 'AWS Cloud Deployment',
          description:
            'Our AWS Cloud Deployment services are tailored to help you smoothly transition to the cloud. We assist with everything from initial setup to full-scale deployment, ensuring a seamless integration with your existing infrastructure. Our team of certified AWS experts ensures that your cloud environment is optimized for performance, security, and cost-efficiency.',
          img: awsIcon,
          more: `<span class="font-bold">Key Features of AWS Cloud Architecture:</span>
                  <ul class="space-y-1 list-disc list-outside pl-8 mt-2">
                    <li>
                      Custom Design: Tailored cloud architecture that fits your specific
                      business needs.
                    </li>
                    <li>
                      High Availability: Design for redundancy and failover to ensure continuous
                      operation.
                    </li>
                    <li>
                      Security: Implement best practices for data protection and compliance.
                    </li>
                    <li>
                      Performance Optimization: Ensure that your cloud resources are optimized
                      for speed and efficiency.
                    </li>
                  </ul>`,
        },
        {
          title: 'AWS Cloud Architecture',
          description:
            'Designing a robust and scalable cloud architecture is crucial for maximizing the benefits of the cloud. Our AWS Cloud Architecture services focus on creating a well-structured, secure, and efficient cloud environment tailored to your business needs. We ensure that your architecture supports your current requirements and can scale as your business grows.',
          img: awsIcon,
          more: `<span class="font-bold">Key Features of AWS Cloud Architecture:</span>
                  <ul class="space-y-1 list-disc list-outside pl-8 mt-2">
                    <li>
                      Custom Design: Tailored cloud architecture that fits your specific business needs.
                    </li>
                    <li>
                      High Availability: Design for redundancy and failover to ensure continuous operation.
                    </li>
                    <li>
                      Security: Implement best practices for data protection and compliance.
                    </li>
                    <li>
                      Performance Optimization: Ensure that your cloud resources are optimized for speed and efficiency.
                    </li>
                  </ul>`,
        },
      ],
    },
    section3: {
      list_of_stories: [
        {
          id: 1,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
      ],
    },
    section4: {
      list_of_faq: [
        {
          key: '1',
          question: 'What are the benefits of migrating to the cloud?',
          answer:
            'Migrating to the cloud offers several benefits, including increased scalability, cost-efficiency, enhanced security, and flexibility. Cloud services allow you to scale resources up or down based on demand, pay only for the resources you use, benefit from robust security features, and deploy applications and services quickly.',
        },
        {
          key: '2',
          question: `How does AWS ensure the security of my data?`,
          answer:
            'AWS provides a highly secure cloud infrastructure with multiple layers of protection, including encryption, identity and access management, network firewalls, and regular security audits. AWS also complies with numerous global security standards and certifications, ensuring your data is protected at all times.',
        },
        {
          key: '3',
          question: 'What is the difference between AWS cloud deployment and traditional hosting?',
          answer:
            'AWS cloud deployment offers more flexibility, scalability, and cost-efficiency compared to traditional hosting. With AWS, you can easily scale resources, only pay for what you use, and benefit from a wide range of services and tools. Traditional hosting often requires upfront investment in hardware and limited scalability options.',
        },
        {
          key: '4',
          question: 'Can you help with cloud migration and integration?',
          answer:
            'Yes, we offer comprehensive cloud migration and integration services. Our team of certified AWS experts can help you smoothly transition your existing infrastructure to the cloud, ensuring minimal disruption and optimal performance. We also provide integration services to connect your cloud environment with third-party applications and services.',
        },
        {
          key: '5',
          question: 'What are the advantages of using serverless architecture with AWS Lambda?',
          answer:
            'Serverless architecture with AWS Lambda provides several advantages, including automatic scaling, cost-efficiency, and reduced operational overhead. With AWS Lambda, you can build event-driven applications that scale automatically in response to demand, and you only pay for the compute time you use. This approach allows you to focus on developing your application without managing server infrastructure.',
        },
        // Add more FAQs as needed
      ],
    },
  },
  'digital-marketing': {
    section1: {
      title1: 'Transform Your Business with',
      title2: 'Expert Digital Marketing Services',
      title_description:
        'At Shivay Intelligence, we offer a full suite of digital marketing services designed to elevate your online presence and drive business growth. From SEO and Google Ads to social media marketing and email campaigns, our expert team leverages the latest technologies and strategies to deliver measurable results. Let us help you reach your target audience and achieve your marketing goals with our tailored solutions.',
    },
    section2: {
      title: 'Digital Marketing Expertise',
      title_description: [
        'At Shivay Intelligence, our digital marketing team is dedicated to delivering exceptional results for our clients. With years of experience and a deep understanding of the digital landscape, we offer innovative solutions that drive traffic, increase conversions, and boost your online visibility. Our holistic approach to digital marketing ensures that every aspect of your online presence is optimized for success.',
        'By leveraging our comprehensive digital marketing services, Shivay Intelligence can help you maximize your online presence and achieve your business goals. Contact us today to learn more about how we can support your digital marketing efforts.',
      ],
      listOfServices: [
        {
          title: 'SEO Services',
          description:
            "Our search engine optimization (SEO) services aim to improve your website's visibility on search engines, driving organic traffic and enhancing your online presence. We employ the latest SEO techniques to ensure your website ranks high for relevant keywords.",
          img: seoIcon,
        },
        {
          title: 'Google Ads',
          description:
            'We create and manage effective Google Ads campaigns that target the right audience and generate high-quality leads. Our team optimizes your ad spend to ensure maximum return on investment.',
          img: googleAdsIcon,
        },
        {
          title: 'Bing Ads',
          description:
            'In addition to Google Ads, we also specialize in creating and managing Bing Ads campaigns. This allows you to reach a broader audience and maximize your advertising efforts.',
          img: bingIcon,
        },
        {
          title: 'Social Media Marketing',
          description:
            'We harness the power of social media platforms to build your brand, engage with your audience, and drive traffic to your website. Our social media marketing services include content creation, campaign management, and performance analysis.',
          img: socialMediaIcon,
        },
        {
          title: 'Email Marketing',
          description:
            'Our email marketing services are designed to nurture leads, retain customers, and promote your products or services. We create personalized and targeted email campaigns that deliver measurable results.',
          img: emailMarketingIcon,
        },
        {
          title: 'Pay Per Click (PPC)',
          description:
            'Our PPC services help you reach potential customers quickly through targeted ad placements on search engines and social media. We manage your PPC campaigns to ensure cost-effective and impactful results.',
          img: ppcIcon,
        },
      ],
    },
    section3: {
      list_of_stories: [
        {
          id: 1,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
      ],
    },
    section4: {
      list_of_faq: [
        {
          key: '1',
          question: 'What are the benefits of migrating to the cloud?',
          answer:
            'Migrating to the cloud offers several benefits, including increased scalability, cost-efficiency, enhanced security, and flexibility. Cloud services allow you to scale resources up or down based on demand, pay only for the resources you use, benefit from robust security features, and deploy applications and services quickly.',
        },
        {
          key: '2',
          question: `How does AWS ensure the security of my data?`,
          answer:
            'AWS provides a highly secure cloud infrastructure with multiple layers of protection, including encryption, identity and access management, network firewalls, and regular security audits. AWS also complies with numerous global security standards and certifications, ensuring your data is protected at all times.',
        },
        {
          key: '3',
          question: 'What is the difference between AWS cloud deployment and traditional hosting?',
          answer:
            'AWS cloud deployment offers more flexibility, scalability, and cost-efficiency compared to traditional hosting. With AWS, you can easily scale resources, only pay for what you use, and benefit from a wide range of services and tools. Traditional hosting often requires upfront investment in hardware and limited scalability options.',
        },
        {
          key: '4',
          question: 'Can you help with cloud migration and integration?',
          answer:
            'Yes, we offer comprehensive cloud migration and integration services. Our team of certified AWS experts can help you smoothly transition your existing infrastructure to the cloud, ensuring minimal disruption and optimal performance. We also provide integration services to connect your cloud environment with third-party applications and services.',
        },
        {
          key: '5',
          question: 'What are the advantages of using serverless architecture with AWS Lambda?',
          answer:
            'Serverless architecture with AWS Lambda provides several advantages, including automatic scaling, cost-efficiency, and reduced operational overhead. With AWS Lambda, you can build event-driven applications that scale automatically in response to demand, and you only pay for the compute time you use. This approach allows you to focus on developing your application without managing server infrastructure.',
        },
        // Add more FAQs as needed
      ],
    },
  },
  'data-science': {
    section1: {
      title1: 'Transform Your Business with',
      title2: 'Expert Data Science and Data Management Solutions',
      title_description:
        'At Shivay Intelligence, we specialize in providing top-tier Data Science and Data Management services designed to optimize your business operations and drive growth. Our comprehensive solutions encompass Data Engineering, Data Analytics, Artificial Intelligence (AI), Machine Learning (ML), and MLOps, ensuring that your data is managed efficiently and effectively. Partner with us to leverage the full potential of your data and transform your business.',
    },
    section2: {
      title: 'Data Science Excellence at Shivay Intelligence',
      title_description: [
        'At Shivay Intelligence, we pride ourselves on our expertise in Data Science and Data Management. Our team of skilled professionals is dedicated to delivering innovative solutions that enhance the performance, scalability, and reliability of your data infrastructure. By integrating advanced technologies and best practices, we ensure that your business stays ahead of the curve in an increasingly data-driven world.',
        'By choosing Shivay Intelligence for your Data Science and Data Management needs, you are partnering with a team dedicated to delivering innovative, high-quality solutions that drive business success. Contact us today to learn more about how we can help you optimize your data infrastructure and harness the power of your data.',
      ],
      listOfServices: [
        {
          title: 'Data Engineering',
          description:
            'Our data engineering services focus on building and maintaining robust data pipelines, ensuring the smooth flow and processing of data across your organization. We prioritize data quality testing to guarantee the accuracy and reliability of your data.',
          img: dataEngineeringIcon,
        },
        {
          title: 'Data Analytics',
          description:
            'Our data analytics solutions include web analytics and the creation of interactive dashboards, providing you with valuable insights into your business operations. We help you harness the power of your data to make informed decisions and drive growth.',
          img: dataAnalyticsIcon,
        },
        {
          title: 'Data Science',
          description:
            'We specialize in advanced data science techniques, including AI-ML, NLP, forecasting, and classification models. Our data science services enable you to leverage cutting-edge technologies to solve complex business problems and gain a competitive edge.',
          img: dataScienceIcon,
        },
        {
          title: 'MLOps',
          description:
            'Our MLOps services ensure the seamless integration and deployment of machine learning models into your production environment. We focus on automating and optimizing the ML lifecycle, from development to deployment and monitoring.',
          img: mlOpsIcon,
        },
      ],
    },
    section3: {
      list_of_stories: [
        {
          id: 1,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
      ],
    },
    section4: {
      list_of_faq: [
        {
          key: '1',
          question: 'What is Data Science, and why is it important for my business?',
          answer:
            'Data Science is a field that combines statistical analysis, machine learning, and data engineering to extract insights and knowledge from data. It is important for your business because it enables data-driven decision-making, helps uncover hidden patterns, and provides predictive analytics to drive growth, improve efficiency, and gain a competitive advantage.',
        },
        {
          key: '2',
          question: `How can Data Science services benefit my organization?`,
          answer:
            'Data Science services help you harness the power of your data by applying advanced analytical techniques and machine learning models. This leads to actionable insights, predictive analytics, and improved decision-making, ultimately driving growth, innovation, and operational efficiency within your organization.',
        },
        {
          key: '3',
          question: 'What types of Data Science solutions do you offer?',
          answer:
            'We offer a range of Data Science solutions, including AI-ML models, natural language processing (NLP), forecasting, and classification models. These solutions help you solve complex business challenges, uncover insights from your data, and gain a competitive edge through advanced predictive analytics and data-driven decision-making.',
        },
        {
          key: '4',
          question: 'How do your Data Science services support business growth?',
          answer:
            'Our Data Science services leverage advanced techniques such as AI-ML, natural language processing (NLP), forecasting, and classification models to solve complex business problems. By integrating these data-driven solutions into your operations, you can gain deeper insights, enhance decision-making, and drive innovation, ultimately leading to improved efficiency and a significant competitive advantage.',
        },
        {
          key: '5',
          question: 'What is MLOps, and how does it enhance the deployment of machine learning models?',
          answer:
            'MLOps is the practice of automating and streamlining the entire lifecycle of machine learning models, from development to deployment and monitoring. It enhances the deployment process by ensuring seamless integration into production environments, reducing manual intervention, and improving the efficiency, scalability, and reliability of machine learning operations. This leads to faster deployment cycles, better model performance, and more consistent outcomes for your business.',
        },
        // Add more FAQs as needed
      ],
    },
  },
  database: {
    section1: {
      title1: 'Transform Your Business with',
      title2: 'Database Solutions',
      title_description:
        'At Shivay Intelligence, we specialize in providing top-tier database management services to ensure your business data is secure, accessible, and efficiently managed. Our expertise spans across various cutting-edge database technologies, allowing us to deliver customized solutions that meet the unique needs of your business. Whether you require robust storage, high-speed access, or seamless integration, our team has the experience and knowledge to enhance your data management capabilities.',
    },
    section2: {
      title: 'Database Management Services',
      title_description: [
        'At Shivay Intelligence, our commitment to excellence in database management is driven by our desire to empower businesses with reliable, scalable, and efficient data solutions. With years of experience and a team of skilled professionals, we have successfully managed and optimized databases for numerous clients, ensuring their data infrastructure supports their growth and operational goals. We understand the critical role that data plays in today’s digital landscape and strive to provide services that enhance your business operations and decision-making processes.',
        'By choosing Shivay Intelligence for your DevOps and Data Management needs, you are partnering with a team dedicated to delivering innovative, high-quality solutions that drive business success. Contact us today to learn more about how we can help you optimize your IT infrastructure and harness the power of your data.',
      ],
      listOfServices: [
        {
          title: 'DynamoDB',
          description:
            'We leverage Amazon DynamoDB to provide a fully managed, serverless NoSQL database service that ensures low latency and high performance. This technology is ideal for applications requiring consistent, single-digit millisecond response times and automatic scaling.',
          img: dynamoDbIcon,
        },
        {
          title: 'MongoDB',
          description:
            "Our use of MongoDB allows us to deliver flexible, scalable, and high-performance database solutions. MongoDB's document-oriented structure is perfect for handling diverse data types and complex queries, making it an excellent choice for modern applications.",
          img: mongoDbIcon,
        },
        {
          title: 'RDS',
          description:
            'With Amazon RDS, we offer a fully managed relational database service that simplifies the setup, operation, and scaling of a relational database in the cloud. RDS supports multiple database engines, including MySQL, PostgreSQL, and SQL Server, providing robust and scalable solutions for a wide range of applications.',
          img: rdsIcon,
        },
        {
          title: 'ElastiCache',
          description:
            'We implement Amazon ElastiCache to provide fast, managed in-memory caching solutions that enhance the performance of web applications by reducing latency and improving throughput. ElastiCache supports both Redis and Memcached, offering flexibility and high availability for caching needs.',
          img: elasticIcon,
        },
      ],
    },
    section3: {
      list_of_stories: [
        {
          id: 1,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 2,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
        {
          id: 3,
          title: 'Lorem ipsum dolor sit amet',
          description: 'Lorem ipsum dolor sit amet consectetur. Felis et urna dictumst hendrerit egestas felis arcu lectus nec. Id pellentesque tortor nascetur urna porta.',
          image: screenImg2,
        },
      ],
    },
    section4: {
      list_of_faq: [
        {
          key: '1',
          question: 'What are the benefits of using DynamoDB for my applications?',
          answer:
            'DynamoDB offers low latency, high performance, and automatic scaling, making it ideal for applications that require consistent, single-digit millisecond response times. It also provides a fully managed, serverless solution, reducing operational overhead and simplifying management.',
        },
        {
          key: '2',
          question: `How can MongoDB enhance my data management capabilities?`,
          answer:
            "MongoDB's flexible, document-oriented structure allows for easy handling of diverse data types and complex queries. Its scalability and high performance make it suitable for modern applications that require efficient data storage and retrieval.",
        },
        {
          key: '3',
          question: 'What advantages does Amazon RDS provide for relational databases?',
          answer:
            'Amazon RDS simplifies the setup, operation, and scaling of relational databases, supporting multiple database engines such as MySQL, PostgreSQL, and SQL Server. It offers automated backups, patching, and scaling, reducing administrative tasks and ensuring high availability and reliability.',
        },
        {
          key: '4',
          question: 'How does ElastiCache improve the performance of web applications?',
          answer:
            'ElastiCache provides fast, in-memory caching solutions that reduce latency and increase throughput for web applications. By supporting both Redis and Memcached, ElastiCache offers flexible and highly available caching options that enhance overall application performance.',
        },
        {
          key: '5',
          question: 'Why should I choose Shivay Intelligence for database management services?',
          answer:
            'At Shivay Intelligence, we bring years of experience and a team of skilled professionals to deliver customized database management solutions tailored to your business needs. Our expertise in advanced technologies like DynamoDB, MongoDB, RDS, and ElastiCache ensures that your data infrastructure is reliable, scalable, and efficient, supporting your growth and operational goals.',
        },
        // Add more FAQs as needed
      ],
    },
  },
};

import industryLogo from '../Images/Portfolio/Industry.png';
import regionLogo from '../Images/Portfolio/region.png';
import technologyLogo from '../Images/Portfolio/technology.png';
import intigrationsLogo from '../Images/Portfolio/intigrations.png';
import cloudLogo from '../Images/Portfolio/cloud.png';
import platformLogo from '../Images/Portfolio/platform.png';
import visitLogo from '../Images/Portfolio/visit.png';

// tla
import tlaBannerImage from '../Images/Portfolio/TLA/bannerImage.svg';
import TLA_logo from '../Images/Portfolio/TLA/TLA_logo.png';
import TLA_list_image from '../Images/Portfolio/TLA/TLA_list_image.png';

// synergi
import Synergi_logo from '../Images/Portfolio/Synergi/synergi_logo.png';
import Synergi_list_image from '../Images/Portfolio/Synergi/synergi_list_image.png';
import synergi_banner from '../Images/Portfolio/Synergi/synergi banner.png';

// HPFS Images
import HpfsLogo from '../Images/Portfolio/hpfs/hpfs_logo.png';
import HpfsListImage from '../Images/Portfolio/hpfs/hpfs_list_image.png';
import HpfsAdminResult1 from '../Images/Portfolio/hpfs/hpfs_admin_result_1.png';
import HpfsAdminResult2 from '../Images/Portfolio/hpfs/hpfs_admin_result_2.png';
import HpfsAdminResult3 from '../Images/Portfolio/hpfs/hpfs_admin_result_3.png';
import HpfsAdminResult4 from '../Images/Portfolio/hpfs/hpfs_admin_result_4.png';
import HpfsAdminResult5 from '../Images/Portfolio/hpfs/hpfs_admin_result_5.png';

// HPFS Mobile
import HpfsResult1 from '../Images/Portfolio/hpfs/mobile_application/hpfs_mobile_result_1.png';
import HpfsResult2 from '../Images/Portfolio/hpfs/mobile_application/hpfs_mobile_result_2.png';
import HpfsResult3 from '../Images/Portfolio/hpfs/mobile_application/hpfs_mobile_result_3.png';
import HpfsResult4 from '../Images/Portfolio/hpfs/mobile_application/hpfs_mobile_result_4.png';
import HpfsResult5 from '../Images/Portfolio/hpfs/mobile_application/hpfs_mobile_result_5.png';
import HpfsResult6 from '../Images/Portfolio/hpfs/mobile_application/hpfs_mobile_result_6.png';

// humannessing
import HumannessingLogo from '../Images/Portfolio/humannessing/humannessLogo.svg';
import Humannessing_list_image from '../Images/Portfolio/humannessing/humanness_list_image.png';
import HumannessingBanner from '../Images/Portfolio/humannessing/humanessing_banner.png';
import HumannessingResult1 from '../Images/Portfolio/humannessing/humanessing_result_1.png';
import HumannessingResult2 from '../Images/Portfolio/humannessing/humanessing_result_2.png';
import HumannessingResult3 from '../Images/Portfolio/humannessing/humenessing_result_3.png';
import HumannessingResult4 from '../Images/Portfolio/humannessing/humenessing_result_4.png';
import HumannessingResult5 from '../Images/Portfolio/humannessing/humenessing_result_5.png';
import HumannessingResult6 from '../Images/Portfolio/humannessing/humenessing_result_6.png';

// Diamond Vishwa
import DiamondVishwaLogo from '../Images/Portfolio/diamond_vishwa/diamond_vishwa_logo.png';
import DiamondVishwaListImage from '../Images/Portfolio/diamond_vishwa/diamond_vishwa_list_image.png';
import DiamondVishwaResult1 from '../Images/Portfolio/diamond_vishwa/diamond_vishwa_result_1.png';
import DiamondVishwaResult2 from '../Images/Portfolio/diamond_vishwa/diamond_vishwa_result_2.png';
import DiamondVishwaResult3 from '../Images/Portfolio/diamond_vishwa/diamond_vishwa_result_3.png';
import DiamondVishwaResult4 from '../Images/Portfolio/diamond_vishwa/diamond_vishwa_result_4.png';

export const portfolioList = [
  {
    tags: ['AWS Cloud', 'Serverless', 'AI/ML', 'Analytics'],
    project_path: 'tla-the_leander_app',
    project_name: 'TLA - The Lenders App',
    logo: TLA_logo,
    banner_title: '<p>Comprehensive <span class="text-[#fe7a36]">Mortgage Analytics</span> with Cutting-Edge Technologies</p>',
    banner_image: TLA_logo,
    list_image: TLA_list_image,
    project_details: [
      {
        icon: industryLogo,
        title: 'Industry',
        value: ['Mortgage'],
      },
      {
        icon: regionLogo,
        title: 'Region',
        value: ['United States'],
      },
      {
        icon: technologyLogo,
        title: 'Technology',
        value: ['ReactJs, Python,Serverless'],
      },
      {
        icon: intigrationsLogo,
        title: 'Integrations',
        value: ['Github Action'],
      },
      {
        icon: cloudLogo,
        title: 'Cloud Infrastructure',
        value: ['Terraform'],
      },
      {
        icon: platformLogo,
        title: 'Platform',
        value: ['Web Application', 'AI/ML Platform'],
      },
      {
        icon: visitLogo,
        title: 'Visit',
        value: 'https://thelenders.app/',
      },
    ],
    company_details: {
      project_brief:
        'The Lenders App has been mastering mortgage analytics for over two decades, offering tools and features refined through practical application and success in real mortgage environments. The platform consolidates critical analytics and engagement tools, reducing complexity and enhancing efficiency. It provides tailored solutions to meet unique business challenges, with advanced portfolio management, lead nurturing, channel management, and borrower engagement capabilities. Additionally, it offers predictive modeling, no-code analytics, and rapid deployment of modular solutions for scalable, data-driven decision-making.',
      service_img: '',
      service_provided: [
        'Advanced Portfolio Analytics',
        'Lead Nurture Optimization',
        'Channel Management Efficiency',
        'Engagement Improvement',
        'Predictive Modeling Tools',
        'No-Code Analytics',
        'Rapid Modular Solution Deployment',
        'AWS Data Integration',
      ],
      challenges: [
        'Optimizing portfolio management to uncover key opportunities.',
        'Enhancing lead sourcing strategies and reducing costs.',
        'Managing multiple sales channels efficiently.',
        'Executing multi-channel engagement strategies.',
        'Deploying predictive models for strategic decision-making.',
        'Conducting complex analytics without coding.',
        'Swiftly adapting to market conditions with modular solutions.',
        'Ensuring secure and efficient data management on AWS Cloud.',
      ],
      shivay_approch: {
        description:
          'Shivay Intelligence offers a comprehensive approach to mortgage marketing analytics, focusing on maximizing efficiency and effectiveness. By leveraging advanced data analytics, AI-driven insights, and user-friendly tools, we help mortgage lenders optimize their portfolio management, lead nurturing, and borrower engagement strategies. Our solutions are designed to address the unique challenges of the mortgage industry, providing scalable, data-driven decision-making capabilities.',
        key_fetures: [
          'Advanced Data Analytics',
          'AI-Driven Insights',
          'Optimized Portfolio Management',
          'Enhanced Lead Nurturing',
          'Improved Borrower Engagement',
          'Scalable Solutions',
          'Data-Driven Decision Making',
        ],
      },
      results: {
        description:
          'Shivay Intelligence has delivered significant results in the mortgage industry through its innovative analytics and marketing solutions. Our approach has led to optimized portfolio management, enhanced lead nurturing, and improved borrower engagement. By leveraging advanced data analytics and AI-driven insights, we have enabled lenders to make data-driven decisions, resulting in increased efficiency and effectiveness across their operations.',
        images: [tlaBannerImage],
      },
    },
  },
  {
    tags: ['AWS Cloud', 'Serverless', 'AI/ML', 'Analytics'],
    project_path: 'synergi',
    project_name: 'Synergi',
    logo: Synergi_logo,
    banner_title: '<p>Harness Synergi for <span class="text-[#fe7a36]">Mortgage Marketing</span> Success</p>',
    banner_image: Synergi_logo,
    list_image: Synergi_list_image,
    project_details: [
      {
        icon: industryLogo,
        title: 'Industry',
        value: ['Mortgage'],
      },
      {
        icon: regionLogo,
        title: 'Region',
        value: ['United States'],
      },
      {
        icon: technologyLogo,
        title: 'Technology',
        value: ['ReactJs, Python,Serverless'],
      },
      {
        icon: intigrationsLogo,
        title: 'Integrations',
        value: ['Github Action'],
      },
      {
        icon: cloudLogo,
        title: 'Cloud Infrastructure',
        value: ['Terraform'],
      },
      {
        icon: platformLogo,
        title: 'Platform',
        value: ['Web Application', 'AI/ML Platform'],
      },
      {
        icon: visitLogo,
        title: 'Visit',
        value: ['https://www.synergi.ai/'],
      },
    ],
    company_details: {
      project_brief:
        'With Synergi, you can automate routine tasks, such as lead management and follow-ups, freeing up your time to focus on what matters most - building meaningful client relationships. Our platform seamlessly integrates with your existing systems, creating a unified ecosystem that maximizes efficiency. Moreover, our advanced AI algorithms enable you to predict market trends and make data-driven decisions, giving you a competitive edge in the ever-evolving mortgage landscape.',
      service_img: '',
      service_provided: [
        'Advanced Portfolio Analytics',
        'Lead Nurture Optimization',
        'Channel Management Efficiency',
        'Engagement Improvement',
        'Predictive Modeling Tools',
        'No-Code Analytics',
        'Rapid Modular Solution Deployment',
        'AWS Data Integration',
      ],
      challenges: [
        'Optimizing portfolio management to uncover key opportunities.',
        'Enhancing lead sourcing strategies and reducing costs.',
        'Managing multiple sales channels efficiently.',
        'Executing multi-channel engagement strategies.',
        'Deploying predictive models for strategic decision-making.',
        'Conducting complex analytics without coding.',
        'Swiftly adapting to market conditions with modular solutions.',
        'Ensuring secure and efficient data management on AWS Cloud.',
      ],
      shivay_approch: {
        description:
          'Shivay Intelligence offers a comprehensive approach to mortgage marketing analytics, focusing on maximizing efficiency and effectiveness. By leveraging advanced data analytics, AI-driven insights, and user-friendly tools, we help mortgage lenders optimize their portfolio management, lead nurturing, and borrower engagement strategies. Our solutions are designed to address the unique challenges of the mortgage industry, providing scalable, data-driven decision-making capabilities.',
        key_fetures: [
          'Advanced Data Analytics',
          'AI-Driven Insights',
          'Optimized Portfolio Management',
          'Enhanced Lead Nurturing',
          'Improved Borrower Engagement',
          'Scalable Solutions',
          'Data-Driven Decision Making',
        ],
      },
      results: {
        description:
          'Shivay Intelligence has delivered significant results in the mortgage industry through its innovative analytics and marketing solutions. Our approach has led to optimized portfolio management, enhanced lead nurturing, and improved borrower engagement. By leveraging advanced data analytics and AI-driven insights, we have enabled lenders to make data-driven decisions, resulting in increased efficiency and effectiveness across their operations.',
        images: [synergi_banner],
      },
    },
  },
  {
    tags: ['AWS Cloud', 'Serverless', 'AI/ML', 'Analytics', 'ReactJs'],
    project_path: 'hpfs',
    project_name: 'HPFS - Harbor Pilot Financial Solution',
    logo: HpfsLogo,
    banner_title: '<p>Use Eticket <span class="text-[#fe7a36]">Speed Up Cash</span> Flow and Cut Costs</p>',
    banner_image: HpfsLogo,
    list_image: HpfsListImage,
    project_details: [
      {
        icon: industryLogo,
        title: 'Industry',
        value: ['Cargo Shipping'],
      },
      {
        icon: regionLogo,
        title: 'Region',
        value: ['United States'],
      },
      {
        icon: technologyLogo,
        title: 'Technology',
        value: ['ReactJs, Python,Serverless'],
      },
      {
        icon: intigrationsLogo,
        title: 'Integrations',
        value: ['Github Action'],
      },
      {
        icon: cloudLogo,
        title: 'Cloud Infrastructure',
        value: ['AWS'],
      },
      {
        icon: platformLogo,
        title: 'Platform',
        value: ['Web Application'],
      },
      {
        icon: visitLogo,
        title: 'Visit',
        value: ['https://d3mvardlsyjf0y.cloudfront.net/'],
      },
    ],
    company_details: {
      project_brief: `HPFS (Harbor Pilots Financial Solution) is a robust financial management platform tailored for the unique needs of the maritime industry, specifically designed for harbor pilots. The platform efficiently manages vessel details, ticketing, and invoicing processes, providing a comprehensive solution for maritime organizations. By automating and streamlining these essential operations, HPFS enhances accuracy, reduces administrative burdens, and ensures that financial transactions are handled with precision. This specialized tool is integral to the smooth financial management of harbor pilot organizations, supporting their critical role in the maritime sector.`,
      service_img: '',
      service_provided: [
        'Comprehensive Financial Management System Development',
        'Vessel and Ticketing Management Integration',
        'Custom Software Development',
        'User-Friendly Interface Design',
        'Automation and Streamlining of Processes',
        'Data Security and Compliance',
        'Ongoing Maintenance and Support',
      ],
      challenges: [
        'Understanding Industry-Specific Requirements',
        'Complex Data Management',
        'Customization and Flexibility',
        'Integration with Existing Systems',
        'Compliance with Industry Regulations',
        'Tight Project Timelines',
        'Data Security',
      ],
      shivay_approch: {
        description:
          'At Shivay Intelligence, we developed a specialized financial management platform, HPFS (Harbor Pilots Financial Solution), tailored to the unique needs of the maritime industry, particularly harbor pilots. Our approach focused on creating a robust system that manages complex vessel details, ticketing processes, and financial transactions while ensuring seamless integration with existing workflows. We faced the challenge of understanding the specific requirements of harbor pilot organizations and implemented a secure, compliant, and user-friendly platform within tight project timelines. Through continuous collaboration with the client, we delivered a solution that not only automated routine tasks but also enhanced accuracy, efficiency, and data security across their operations.',
        key_fetures: [
          'Comprehensive Financial Management',
          'Vessel and Ticketing Management',
          'Custom Software Development',
          'User-Friendly Interface',
          'Automation and Process Optimization',
          'Data Security and Compliance',
          'Integration with Existing Systems',
        ],
      },
      results: {
        description:
          'The result was a powerful and efficient financial management platform that seamlessly integrated with the unique workflows of harbor pilot organizations. The HPFS system successfully automated complex processes such as vessel management, ticketing, and financial transactions, leading to significant improvements in accuracy and operational efficiency. Delivered within the tight project timelines, the platform provided a secure, compliant, and user-friendly solution that met the specific needs of the maritime industry, enhancing overall productivity and ensuring smooth, uninterrupted operations for harbor pilots.',
        images: [HpfsAdminResult1, HpfsAdminResult2, HpfsAdminResult3, HpfsAdminResult4, HpfsAdminResult5],
      },
    },
  },
  {
    tags: ['AWS Cloud', 'Serverless', 'AI/ML', 'Analytics'],
    project_path: 'hpfs_mobile_application',
    project_name: 'HPFS - Harbor Pilot Financial Solution (Mobile Application)',
    logo: HpfsLogo,
    banner_title: '<p>Use Eticket <span class="text-[#fe7a36]">Speed Up Cash</span> Flow and Cut Costs</p>',
    banner_image: HpfsLogo,
    list_image: HpfsListImage,
    project_details: [
      {
        icon: industryLogo,
        title: 'Industry',
        value: ['Cargo Shipping'],
      },
      {
        icon: regionLogo,
        title: 'Region',
        value: ['United States'],
      },
      {
        icon: technologyLogo,
        title: 'Technology',
        value: ['Flutter'],
      },
      {
        icon: intigrationsLogo,
        title: 'Integrations',
        value: ['Github Action'],
      },
      {
        icon: cloudLogo,
        title: 'Cloud Infrastructure',
        value: ['AWS'],
      },
      {
        icon: platformLogo,
        title: 'Platform',
        value: ['Mobile Application'],
      },
      {
        icon: visitLogo,
        title: 'Visit',
        value: ['https://d3mvardlsyjf0y.cloudfront.net/'],
      },
    ],
    company_details: {
      project_brief: `HPFS (Harbor Pilots Financial Solution) is a comprehensive financial management platform specifically designed for the maritime industry, focusing on the unique needs of harbor pilot organizations. The platform automates the management of vessel details, ticketing processes, and financial transactions, providing a seamless and efficient solution. Shivay Intelligence developed this robust system to integrate smoothly with existing workflows, ensuring that harbor pilots can manage their operations with enhanced accuracy, security, and efficiency.`,
      service_img: '',
      service_provided: [
        'Custom Financial Management System Development',
        'Vessel and Ticketing Management Integration',
        'User-Friendly Interface Design',
        'Automation of Financial Processes',
        'Data Security and Compliance',
        'Ongoing Maintenance and Support',
      ],
      challenges: [
        'Understanding Industry-Specific Requirements',
        'Complex Data Management',
        'Customization and Flexibility',
        'Compliance with Industry Regulations',
        'Tight Project Timelines',
      ],
      shivay_approch: {
        description:
          'At Shivay Intelligence, we approached the HPFS project by first gaining a deep understanding of the unique financial and operational requirements of harbor pilot organizations. We focused on developing a robust, secure, and user-friendly platform that could seamlessly integrate with existing workflows. Our team worked closely with the client to ensure that the system met all regulatory standards while delivering enhanced accuracy and efficiency. Despite the challenges of managing complex data and meeting tight deadlines, we maintained a commitment to quality, resulting in a solution that effectively streamlined maritime operations.',
        key_fetures: [
          'Comprehensive Financial Management',
          'Vessel and Ticketing Management',
          'User-Friendly Interface',
          'Automation of Routine Tasks',
          'Ongoing Support',
          'Seamless System Integration',
        ],
      },
      results: {
        description:
          'The result was a powerful financial management platform that met the specific needs of harbor pilot organizations in the maritime industry. The HPFS system successfully automated complex processes, leading to improved accuracy and operational efficiency. Delivered within the tight project timelines, the platform provided a secure, compliant, and user-friendly solution that seamlessly integrated with existing workflows. This enhanced the productivity and overall effectiveness of the organizations, ensuring smooth and uninterrupted operations.',
        images: [HpfsResult1, HpfsResult2, HpfsResult3, HpfsResult4, HpfsResult5, HpfsResult6],
      },
    },
  },
  {
    tags: ['ReactJs'],
    project_path: 'humannessing',
    project_name: 'Humanness.ing',
    logo: HumannessingLogo,
    banner_title: '<p>We unhuman. More. <span class="text-[#fe7a36]">We human. Less.</span></p>',
    banner_image: HumannessingLogo,
    list_image: Humannessing_list_image,
    project_details: [
      {
        icon: industryLogo,
        title: 'Industry',
        value: ['Book'],
      },
      {
        icon: regionLogo,
        title: 'Region',
        value: ['United States'],
      },
      {
        icon: technologyLogo,
        title: 'Technology',
        value: ['ReactJs'],
      },
      {
        icon: intigrationsLogo,
        title: 'Integrations',
        value: ['Github Action'],
      },
      {
        icon: platformLogo,
        title: 'Platform',
        value: ['Web Application'],
      },
      {
        icon: visitLogo,
        title: 'Visit',
        value: ['https://www.humanness.ing/'],
      },
    ],
    company_details: {
      project_brief:
        'Humanness.ai is an innovative platform designed to guide users on a journey of personal growth and resilience. It offers a transformative approach to self-exploration through its unique pause-linger-witness process, which provides guided insights into human behavior and coaching science. The platform encourages users to engage in meaningful self-dialogue, fostering the development of intuitive intelligence and a deeper understanding of oneself.',
      service_img: '',
      service_provided: [
        'Development of a Personal Growth Platform',
        'Implementation of Unique Coaching Processes',
        'User Engagement and Self-Dialogue Tools',
        'Behavioral Insights and Coaching Science Integration',
        'User-Friendly Interface Design',
      ],
      challenges: ['Incorporating Complex Psychological Concepts', 'Encouraging Deep User Engagement', 'Balancing Guidance with User Autonomy', 'Maintaining Simplicity in Design'],
      shivay_approch: {
        description:
          'At Shivay Intelligence, we approached the development of Humanness.ai with a focus on creating a platform that blends science and personal exploration seamlessly. Our team worked diligently to integrate complex psychological concepts into an intuitive interface, ensuring that users could engage deeply with the content without feeling overwhelmed. By leveraging our expertise in user experience design and behavioral science, we crafted a platform that supports personal growth and resilience in a meaningful and accessible way.',
        key_fetures: [
          'Pause-Linger-Witness Process',
          'Guided Insights into Human Behavior',
          'Self-Dialogue Tools',
          'User-Friendly Interface',
          'Personal Growth and Resilience Focus',
          'Science-Driven Content',
        ],
      },
      results: {
        description:
          'The result was a highly effective platform that successfully blends human behavior insights with personal growth practices. Humanness.ai provides users with a structured yet flexible approach to exploring their thoughts and behaviors, fostering resilience and deeper self-awareness. The platform’s user-friendly design and science-driven content have made it a valuable tool for individuals seeking to engage in meaningful self-dialogue and personal development.',
        images: [HumannessingResult1, HumannessingResult2, HumannessingResult3, HumannessingResult4, HumannessingResult5, HumannessingResult6],
      },
    },
  },
  {
    tags: ['Wordpress'],
    project_path: 'diamond_vishwa',
    project_name: 'DIAMOND VISHWA',
    logo: DiamondVishwaLogo,
    banner_title: '<p>Crafting <span class="text-[#fe7a36]">Timeless Elegance,</span> One Diamond at a Time</p>',
    banner_image: DiamondVishwaLogo,
    list_image: DiamondVishwaListImage,
    project_details: [
      {
        icon: industryLogo,
        title: 'Industry',
        value: ['Diamond'],
      },
      {
        icon: regionLogo,
        title: 'Region',
        value: ['Canada'],
      },
      {
        icon: technologyLogo,
        title: 'Technology',
        value: ['Wordpress'],
      },
      {
        icon: platformLogo,
        title: 'Platform',
        value: ['Web Application'],
      },
      {
        icon: visitLogo,
        title: 'Visit',
        value: ['https://diamondvishwa.com'],
      },
    ],
    company_details: {
      project_brief:
        'Explore Diamond Vishwa’s unparalleled collection of handcrafted jewelry, meticulously fashioned with passion and precision. Our portfolio showcases an array of bespoke adornments that are not just pieces of jewelry, but timeless treasures that reflect sophistication and elegance. Each creation exemplifies the dedication of our artisans to crafting pieces infused with love and artistry. Dive into our world of handcrafted excellence and let your inner radiance shine with Diamond Vishwa.',
      service_img: '',
      service_provided: [
        'Custom E-commerce Website Development',
        'High Traffic Management',
        'Speed and Performance Optimization',
        'CMS Integration',
        'Responsive Design',
        'SEO Optimization',
        'Quick Turnaround Time',
        'User-Friendly Interface',
        'Secure Payment Gateway Integration',
        'Ongoing Support and Maintenance',
      ],
      challenges: ['High Traffic Management', 'Performance Optimization', 'CMS Integration', 'Tight Project Deadline', 'Search Engine Optimization', 'Scalability'],
      shivay_approch: {
        description:
          'At Shivay Intelligence, we tackled the challenge of developing a high-performance e-commerce platform for DiamondVishwa, a Surat-based luxury jewelry startup targeting the US and Canada. Our approach focused on creating a scalable website capable of handling high traffic without compromising speed or performance. We integrated a user-friendly CMS for easy product management and optimized the site for search engines to reach the target audience effectively. Despite a tight 2-3 week deadline, we successfully delivered a responsive, SEO-optimized website with secure payment gateways, ensuring a seamless user experience across all devices.',
        key_fetures: [
          'High-Performance E-commerce Platform',
          'Performance Optimization',
          'User-Friendly CMS Integration',
          'Responsive Design',
          'SEO Optimization',
          'Secure Payment Gateway Integration',
          'Ongoing Support and Maintenance',
        ],
      },
      results: {
        description: `The result was a fast, scalable e-commerce platform that successfully met DiamondVishwa's needs. The website handled high traffic effortlessly, provided an intuitive CMS for easy product management, and achieved strong search engine visibility, effectively reaching the target audience in the US and Canada. Delivered within the 2-3 week deadline, the responsive and secure website offered a seamless shopping experience, positioning DiamondVishwa strongly in the competitive luxury jewelry market.`,
        images: [DiamondVishwaResult1, DiamondVishwaResult2, DiamondVishwaResult3, DiamondVishwaResult4],
      },
    },
  },
];

export const useCasesList = [
  {
    tags: ['tla-the_leander_app', 'synergi'],
    title: 'Accelerate Website Performance with DynamoDB',
    description: 'Accelerate Website Performance with DynamoDB is a solution designed to enhance the speed and efficiency of websites while keeping costs low.',
    key_benefits: [
      {
        title: 'Cost-effective',
        description: 'DynamoDB operates on a pay-per-use model, making it ideal for scaling without incurring high costs.',
      },
      {
        title: 'Blazing Fast',
        description: 'With low latency and the ability to handle high traffic, DynamoDB ensures your website performs at optimal speed.',
      },
      {
        title: 'Flexible',
        description: 'DynamoDB adapts to changing metadata and supports various data types.',
      },
      {
        title: 'Efficient',
        description: 'DynamoDB manages data expiration with Time-to-Live (TTL), automatically removing outdated data and keeping your database clean and efficient.',
      },
    ],
    case_study: {
      'tla-the_leander_app': {
        description: 'Boosted performance of The Lenders APP and Synergi APP by reducing load times by 32%. Additionally, the cost of data storage decreased by 35%.',
        implementation_time: 'Typically takes around 2 weeks for a 5-page website.',
      },
      synergi: {
        description: 'Boosted performance of The Lenders APP and Synergi APP by reducing load times by 32%. Additionally, the cost of data storage decreased by 35%.',
        implementation_time: 'Typically takes around 2 weeks for a 5-page website.',
      },
    },
    technologies: ['DynamoDB', 'AWS', 'TTL'],
  },
  {
    tags: ['tla-the_leander_app'],
    title: 'Smart Search',
    description:
      "Smart search is a search engine that uses natural language processing (NLP) and machine learning (ML) to understand the user's intent and return the most relevant results.",
    key_benefits: [
      {
        title: 'Improved user experience',
        description: 'Users can find the information they need more quickly and easily.',
      },
      {
        title: 'Increased engagement',
        description: 'Users are more likely to stay on your website or app if they can easily find what they are looking for.',
      },
      {
        title: 'Increased conversions',
        description: 'Users are more likely to convert if they can find the products or services they are looking for.',
      },
      {
        title: 'Reduced customer support costs',
        description: 'Users can find answers to their questions more easily, reducing the need for customer support.',
      },
    ],
    case_study: {
      'tla-the_leander_app': {
        description: 'Implemented the Smart Search facility for The Lenders APP, within 2 weeks of span.',
        implementation_time: '2 weeks',
      },
    },
    technologies: ['NLP', 'ML', 'Smart Search'],
  },
  {
    tags: ['tla-the_leander_app', 'synergi'],
    title: 'Smart Dashboard Creation',
    description: 'Smart Dashboard Creation is a tool that allows users to easily generate charts and tables by asking questions in plain language.',
    key_benefits: [
      {
        title: 'Time-saving',
        description: 'Users can get the information they need instantly, without waiting for analysts to create charts.',
      },
      {
        title: 'Ease of use',
        description: 'Users can ask questions in simple language without needing technical knowledge.',
      },
      {
        title: 'Improved decision-making',
        description: 'Quick access to relevant data helps users make informed decisions faster.',
      },
      {
        title: 'Increased productivity',
        description: 'Analysts can focus on more complex tasks instead of spending time on simple queries.',
      },
    ],
    case_study: {
      'tla-the_leander_app': {
        description:
          'Implemented the Smart Dashboard Creation tool for TLA users, enabling them to generate sales reports and mortgage performance charts instantly, leading to faster decision-making and increased efficiency within the first month.',
        implementation_time: '1 month',
      },
    },
    technologies: ['Smart Dashboard', 'Data Visualization', 'NLP'],
  },
  {
    tags: ['tla-the_leander_app', 'synergi'],
    title: 'Data Mapping System',
    description:
      'Data Mapping System is a comprehensive solution designed to streamline the process of uploading, normalizing, and transforming data for seamless integration and analysis.',
    key_benefits: [
      {
        title: 'Efficient data upload',
        description: 'Fast and secure data transfer using S3 pre-signed URLs.',
      },
      {
        title: 'User-friendly mapping',
        description: 'Easy column mapping and normalization with a React frontend.',
      },
      {
        title: 'Optimized storage',
        description: 'Data is stored in Parquet format for efficient storage and retrieval.',
      },
      {
        title: 'Comprehensive integration',
        description: 'Combines data from multiple sources for versatile analysis and offer creation.',
      },
      {
        title: 'Flexible data handling',
        description: 'Options to append or replace data as needed.',
      },
    ],
    case_study: {
      'tla-the_leander_app': {
        description:
          'Implemented the Data Mapping System for a retail company, enabling them to quickly upload and normalize large datasets. The transformation layer allowed for seamless integration with other data sources, facilitating the creation of personalized offers. This led to improved data management and faster decision-making within the first month.',
        implementation_time: '1 month',
      },
    },
    technologies: ['S3', 'React', 'Parquet', 'Data Integration'],
  },
  {
    tags: ['tla-the_leander_app'],
    title: 'Data Analytics Platform Architecture',
    description:
      'Data Analytics Platform Architecture is specifically designed for loan refinancing marketing, optimized to be cost-effective and scalable regardless of the number of users or data volume.',
    key_benefits: [
      {
        title: 'Cost-effective',
        description: 'The pay-as-you-go model ensures that costs are only incurred for actual usage, making it highly economical.',
      },
      {
        title: 'Scalable',
        description: 'The architecture can easily handle varying volumes of data and user activity without the need for manual scaling.',
      },
      {
        title: 'High Availability',
        description: 'Serverless services offer built-in high availability and fault tolerance.',
      },
      {
        title: 'Optimized Performance',
        description: 'Adhering to best practices ensures the platform performs optimally under different loads.',
      },
    ],
    case_study: {
      'tla-the_leander_app': {
        description:
          'Implemented the Data Analytics Platform Architecture for a loan refinancing marketing company. By switching to a serverless architecture, the company saved around 90% on infrastructure costs. The platform remained highly responsive and reliable, regardless of the fluctuating number of users and data volumes.',
        implementation_time: 'Varies based on complexity',
      },
    },
    technologies: ['Serverless Architecture', 'AWS', 'Cost Optimization'],
  },
];

import React, { useEffect } from 'react';
import './App.css';
import RouterEntry from './routes/RouterEntry';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  useEffect(() => {
    // Function to load an external script
    const addScript = (w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    };

    // Adding the Google Tag Manager script
    addScript(window, document, 'script', 'dataLayer', 'GTM-WKBF438L');

    // You might want to clean up when the component unmounts
    return () => {
      // Remove the script tag if necessary or clear intervals/timeouts if set
    };
  }, [location?.pathname]);

  window.onload = function () {
    var height = document.body.scrollHeight;
    window.parent.postMessage(
      {
        frameHeight: height,
      },
      '*'
    ); // Consider specifying the parent origin instead of '*' for security
  };

  return (
    <div className="App">
      <RouterEntry />
    </div>
  );
}

export default App;

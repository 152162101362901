import React from 'react';
import YourInnovationPartner from '../components/YourInnovationPartner';
import WhoAreWe from '../components/WhoAreWe';
import AboutUsTechStack from '../components/AboutUsTechStack';
import OurVision from '../components/OurVision';
import WhatOurClientSay from '../components/WhatOurClientSay';
import WhatWeDo from '../components/WhatWeDo';
import OurTeam from '../components/OurTeam';

const AboutUs = () => {
  return (
    <div>
      <YourInnovationPartner />
      <WhoAreWe />
      <AboutUsTechStack />
      <OurVision />
      <OurTeam />
      <WhatOurClientSay />
      <WhatWeDo />
    </div>
  );
};

export default AboutUs;

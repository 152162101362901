import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader';
import { Button, Input } from 'antd';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
const Blogs = () => {
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postPerPage = 6;

  // pagination
  const [index, setIndex] = useState(1);
  const [numberOfPage, setNumberOfPage] = useState(0);

  const location = useLocation();
  const [pageType, setPageType] = useState('web-application');
  const navigate = useNavigate();
  useEffect(() => {
    if (location.search === '') {
      navigate('/blogs?type=web-application');
    } else if (typeof queryString.parse(location.search) === 'object' && !Object.keys(queryString.parse(location.search)).includes('type')) {
      navigate('/blogs?type=web-application');
    } else if (!queryString.parse(location.search).type) {
      navigate('/blogs?type=web-application');
    } else {
      setPageType(queryString.parse(location.search).type);
    }
  }, [location.search]);

  const blogReducer = useSelector((state) => state.allBlogsReducer.allBlogs);

  const [blogList, setBlogList] = useState([]);
  useEffect(() => {
    setIsLoading(blogReducer.loading);
  }, []);

  useEffect(() => {
    if (blogReducer.success) {
      const data = blogReducer.data.items;
      const filter = data.filter((post) => post.metadata.tags.some((tag) => tag.sys.id === pageType));
      setNumberOfPage(Math.ceil(filter.length / postPerPage));
      setBlogList(filter);
    }
  }, [pageType, blogReducer]);

  const handleViewMore = () => {
    setIndex(index + 1);
  };

  return (
    <div>
      <div className="w-full bg-cover bg-no-repeat bg-center border-b-[1px] border-b-[#343050]">
        <div className="px-6 xl:px-50 md:px-20  pt-16">
          <div className="flex flex-col gap-3">
            <div className="text-[#314ED9] text-center font-poppins md:leading-[50px] leading-[40px] text-[35px] md:text-[40px]  font-bold  w-full ">
              Blogs | Shivay Intelligence
            </div>
            <div className=" text-center  md:leading-[50px] leading-[40px] text-[15px] md:text-[24px]  font-normal  w-full ">
              Translating your ideas from thoughts to market products.
            </div>
          </div>
          <div className="mt-4">
            <div className="text-sm md:text-lg mb-4 font-semibold text-center text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-700">
              <div className="sm:hidden ">
                <label htmlFor="tabs" className="sr-only">
                  {pageType}
                </label>
                <select
                  id="tabs"
                  value={pageType}
                  onChange={(e) => {
                    navigate(`/blogs?type=${e.target.value}`);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {/* <option value={'full-stack-application'}>Full Stack Applications</option> */}
                  <option value={'web-application'}>Web Applications</option>
                  <option value={'mobile-application'}>Mobile Applications</option>
                  <option value={'database'}>Database consultancy</option>
                  <option value={'cloud'}>AWS Cloud Management</option>
                  <option value={'digital-marketing'}>Digital Marketing</option>
                  <option value={'data-science'}>Data Science & Data Engineering Services</option>
                </select>
              </div>
              <ul className="hidden sm:flex flex-wrap justify-center -mb-px ">
                {/* <li className="me-2">
                  <NavLink
                    to="/blogs?type=web-application"
                    className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${
                      pageType === 'web-application' ? 'text-[#fe7a36]' : 'text-gray-600'
                    }`}
                  >
                    Full Stack Applications
                  </NavLink>
                </li> */}
                <li className="me-2">
                  <NavLink
                    to="/blogs?type=web-application"
                    className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'web-application' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                  >
                    Web Applications
                  </NavLink>
                </li>
                <li className="me-2">
                  <NavLink
                    to="/blogs?type=mobile-application"
                    className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'mobile-application' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                    aria-current="page"
                  >
                    Mobile Applications
                  </NavLink>
                </li>
                <li className="me-2">
                  <NavLink
                    to="/blogs?type=database"
                    className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'database' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                  >
                    Database consultancy
                  </NavLink>
                </li>
                <li className="me-2">
                  <NavLink
                    to="/blogs?type=cloud"
                    className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'cloud' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                  >
                    AWS Cloud Management
                  </NavLink>
                </li>
                <li className="me-2">
                  <NavLink
                    to="/blogs?type=digital-marketing"
                    className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'digital-marketing' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                  >
                    Digital Marketing
                  </NavLink>
                </li>
                <li className="me-2">
                  <NavLink
                    to="/blogs?type=data-science"
                    className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'data-science' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                  >
                    Data Science & Data Engineering Services
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 xl:px-64 py-12 ">
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div className="container grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-3 ">
              {blogList.map((item) => {
                return (
                  <div
                    key={item.sys.id}
                    className="w-full flex-wrap rounded-2xl shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)] bg-white cursor-pointer"
                    onClick={() => {
                      navigate(`/blog/${pageType}/${item.sys.id}`);
                    }}
                  >
                    <img src={item.fields.coverImage.fields.file.url} alt="blog Image" className="rounded-t-2xl h-[180px] sm:h-[250px] w-full" />
                    <div className="p-3">
                      <div className="text-[#152C5B] font-poppins md:text-[24px] text-[18px] font-bold">{item.fields.title}</div>
                      <div className="text-[15px] md:text-[18px] font-normal my-3">{item.fields.metaDescription}</div>
                      <div className="text-[15px] md:text-[18px] font-normal my-3">{item.fields.createdAt}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            {index < numberOfPage && (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Button type="text" className="hover:!bg-[#314ed9] bg-[#152c5b] text-white  fw-bolder" style={{ height: 50, width: 250 }} onClick={handleViewMore}>
                  View More Blogs
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Blogs;

import React from 'react';
import ServiceHeaderComponent from '../components/ServiceHeaderComponent';
import EvolvingTechnology from '../components/EvolvingTechnology';
// import TransformingSuccess from '../components/TransformingSuccess';
import ServiceTechStack from '../components/ServiceTechStack';
import FAQComponent from '../components/FAQComponent';
import DriveYourTech from '../components/DriveYourTech';

const Service = () => {
  return (
    <div>
      <ServiceHeaderComponent />
      <EvolvingTechnology />
      {/* <TransformingSuccess /> */}
      <ServiceTechStack />
      <FAQComponent />
      <DriveYourTech />
    </div>
  );
};

export default Service;

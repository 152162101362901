import React from 'react';
import { TailSpin } from 'react-loader-spinner';

function Loader() {
  return (
    <div className='flex justify-center my-10 py-11'>
      <TailSpin visible={true} height="80" width="80" color="#152C5B" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" />
    </div>
  );
}

export default Loader;

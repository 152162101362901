import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  allBlogs: {
    loading: false,
    success: false,
    data: [],
    error: false,
  },
};

export const blogSlicer = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    getAllBlogs: (state, action) => {
      state.allBlogs.data = action.payload;
      state.allBlogs.success = true;
      state.allBlogs.loading = false;
      state.allBlogs.error = false;
    },
    setBlogLoading: (state, action) => {
      state.allBlogs.loading = true;
      state.allBlogs.error = false;
      state.allBlogs.success = false;
    },
    getErrorInBlogs: (state, action) => {
      state.allBlogs.data = [];
      state.allBlogs.success = false;
      state.allBlogs.loading = false;
      state.allBlogs.error = true;
    },
  },
});

export const { getAllBlogs, setBlogLoading, getErrorInBlogs } = blogSlicer.actions;

export default blogSlicer.reducer;

import React from 'react';
import { Button } from 'antd';
import backgroundImage from '../Images/Future Graphics.png';

const FutureIsHere = () => {
  const handleClick = () => {
    window.open('https://shivayintelligence1.zohobookings.in/#/shivayintelligence', '_blank');
  };
  return (
    <div className="mt-3 md:mt-5 pt-5 font-poppins">
      <div className="grid container-xxl lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1  gap-5 mb-[20px]">
        <div className="grid content-center ">
          <div className="text-[44px] text-right mb-[20px]">
            The <span className="font-[900] text-[#22bb22]">Future</span> is here!
          </div>
          <p className="text-[18px] font-open-sana font-[400] text-right">
            Turn your ideas into captivating development solutions. We offer high-quality, cost-effective, and reliable tech innovations to propel you forward in the digital
            market.
          </p>
        </div>
        <div className="grid content-center">
          <div className="grid content-center">
            <img src={backgroundImage} alt="" style={{ objectFit: 'contain' }} className="h-[350px] lg:h-[480px] md:h-[600px] mx-auto" />
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col justify-center items-center h-full">
        <div className="text-[#314ED9] font-poppins text-center text-[32px] font-[700] mb-[20px]">Looking to expand your business? Book a meeting with us today!</div>

        <div className="text-center mb-[3%]">
          <Button type="text" className="hover:!bg-[#314ed9] bg-[#152c5b] text-white  text-base fw-bolder" style={{ height: 50 }} onClick={handleClick}>
            Schedule Call
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FutureIsHere;

import React from 'react';
import backGround1 from '../Images/contact-us-image-1.jpeg';
import backGround2 from '../Images/contact-us-image-2.jpeg';
import backGround3 from '../Images/contact-us-image-3.jpeg';
import backGround4 from '../Images/contact-us-image-4.jpeg';
import backGround5 from '../Images/contact-us-image-5.jpeg';

const ContactUsBelowBanner = () => {
  const isSmallScreen = window.matchMedia('(max-width: 768px)').matches;
  return (
    <div className="my-5">
      <div className="d-flex flex-column container-xxl">
        <div className="mt-5">
          <div className="contactUsBelowBannerFirstComponent">
            <div
              className="me-3  p-4 relative"
              style={{
                backgroundImage: `url(${backGround1})`,
                width: '100%',
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundRepeat: 'no-repeat', // Prevents image from repeating
                backgroundPosition: 'center', // Centers the image//
                height: '300px',
                borderRadius: '20px',
              }}
            >
              <div className="absolute bg-[#152C5B] top-0 bottom-0 left-0 right-0 opacity-50 rounded-[20px]"></div>
              <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center">
                <div className="contact-us-below-banner-title text-center">AI ML Solutions</div>
                <div className="contact-us-below-subtitle text-center">Elevate efficiency with bespoke AI and ML insights.</div>
              </div>
            </div>
            <div
              className="me-3  p-4 relative"
              style={{
                backgroundImage: `url(${backGround2})`,
                width: '100%',
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundRepeat: 'no-repeat', // Prevents image from repeating
                backgroundPosition: 'center', // Centers the image//
                height: '300px',
                borderRadius: '20px',
                marginTop: isSmallScreen ? '10px' : '0px',
              }}
            >
              <div className="absolute bg-[#152C5B] top-0 bottom-0 left-0 right-0 opacity-50 rounded-[20px]"></div>
              <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center">
                <div className="contact-us-below-banner-title text-center">Cloud Consultancy</div>
                <div className="contact-us-below-subtitle  text-center">Strategic cloud solutions for agile, cost-efficient infrastructure.</div>
              </div>
            </div>
            <div
              className="me-3  p-4 relative"
              style={{
                backgroundImage: `url(${backGround2})`,
                width: '100%',
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundRepeat: 'no-repeat', // Prevents image from repeating
                backgroundPosition: 'center', // Centers the image//
                height: '300px',
                borderRadius: '20px',
                marginTop: isSmallScreen ? '10px' : '0px',
              }}
            >
              <div className="absolute bg-[#152C5B] top-0 bottom-0 left-0 right-0 opacity-50 rounded-[20px]"></div>
              <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center">
                <div className="contact-us-below-banner-title text-center">Cloud Consultancy</div>
                <div className="contact-us-below-subtitle  text-center">Strategic cloud solutions for agile, cost-efficient infrastructure.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="contactUsBelowBannerSecondComponent mb-5">
          <div
            className="me-3  p-4 relative"
            style={{
              backgroundImage: `url(${backGround3})`,
              width: '100%',
              backgroundSize: 'cover', // Ensures the image covers the entire div
              backgroundRepeat: 'no-repeat', // Prevents image from repeating
              backgroundPosition: 'center', // Centers the image//
              height: '300px',
              borderRadius: '20px',
            }}
          >
            <div className="absolute bg-[#152C5B] top-0 bottom-0 left-0 right-0 opacity-50 rounded-[20px]"></div>
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center">
              <div className="contact-us-below-banner-title text-center">Data Science</div>
              <div className="contact-us-below-subtitle  text-center">Unlock actionable insights with advanced data analytics.</div>
            </div>
          </div>
          <div
            className="me-3  p-4 relative"
            style={{
              backgroundImage: `url(${backGround4})`,
              width: '100%',
              backgroundSize: 'cover', // Ensures the image covers the entire div
              backgroundRepeat: 'no-repeat', // Prevents image from repeating
              backgroundPosition: 'center', // Centers the image//
              height: '300px',
              borderRadius: '20px',
              marginTop: isSmallScreen ? '10px' : '0px',
            }}
          >
            <div className="absolute bg-[#152C5B] top-0 bottom-0 left-0 right-0 opacity-50 rounded-[20px]"></div>
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center">
              <div className="contact-us-below-banner-title text-center">Automatic Marketing Solution</div>
              <div className="contact-us-below-subtitle  text-center">Automate for targeted, impactful marketing campaigns.</div>
            </div>
          </div>
          <div
            className="relative p-4 "
            style={{
              backgroundImage: `url(${backGround5})`,
              width: '100%',
              backgroundSize: 'cover', // Ensures the image covers the entire div
              backgroundRepeat: 'no-repeat', // Prevents image from repeating
              backgroundPosition: 'center', // Centers the image//
              height: '300px',
              borderRadius: '20px',
              marginTop: isSmallScreen ? '10px' : '0px',
            }}
          >
            <div className="absolute bg-[#152C5B] top-0 bottom-0 left-0 right-0 opacity-50 rounded-[20px]"></div>
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center">
              <div className="contact-us-below-banner-title text-center">Web Development</div>
              <div className="contact-us-below-subtitle  text-center">Crafting streamlined, responsive web experiences.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsBelowBanner;

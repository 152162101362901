import React from 'react';
import img1 from '../Images/career-1.png';
import img2 from '../Images/career-2.png';
import img3 from '../Images/career-3.png';
import img4 from '../Images/career-4.png';
import img5 from '../Images/career-5.png';
import img6 from '../Images/career-6.png';

const YourCareerAtShivay = () => {
  return (
    <div className="bg-[#F6F7F8] pb-5 pt-5">
      <div className="container-xxl p-4">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="grid justify-items-center grid-cols-1 sm:grid-cols-2">
            <div className="grid gap-5 pb-7">
              <div className="your-career-tech-card p-3">
                <div style={{ width: '50px', height: '50px', objectFit: 'contain' }}>
                  <img src={img1} alt="" />
                </div>
                <div className="text-[20px] font-poppins font-[700] text-center mt-4">Modern Workspace</div>
                <div className="text-[18px] font-open-sana font-[400] text-center mt-4">Experience efficiency in our well-equipped, ergonomic office setting.</div>
              </div>
              <div className="your-career-tech-card p-3">
                <div style={{ width: '50px', height: '50px', objectFit: 'contain' }}>
                  <img src={img2} alt="" />
                </div>
                <div className="text-[20px] font-poppins font-[700] text-center mt-4">Continual Learning</div>
                <div className="text-[18px] font-open-sana font-[400] text-center mt-4">Enhance your skills through our comprehensive learning initiatives.</div>
              </div>
              <div className="your-career-tech-card p-3">
                <div style={{ width: '50px', height: '50px', objectFit: 'contain' }}>
                  <img src={img3} alt="" />
                </div>
                <div className="text-[20px] font-poppins font-[700] text-center mt-4">Flexible Timings</div>
                <div className="text-[18px] font-open-sana font-[400] text-center mt-4">Flexible timings allow for customizable work schedules.</div>
              </div>
            </div>
            <div className="grid gap-5 mt-7">
              <div className="your-career-tech-card p-3">
                <div style={{ width: '50px', height: '50px', objectFit: 'contain' }}>
                  <img src={img4} alt="" />
                </div>
                <div className="text-[20px] font-poppins font-[700] text-center mt-4">Inspiring Colleagues</div>
                <div className="text-[18px] font-open-sana font-[400] text-center mt-4">Join forces with top-tier professionals in a collaborative atmosphere.</div>
              </div>
              <div className="your-career-tech-card p-3">
                <div style={{ width: '50px', height: '50px', objectFit: 'contain' }}>
                  <img src={img5} alt="" />
                </div>
                <div className="text-[20px] font-poppins font-[700] text-center mt-4">Strategic Location</div>
                <div className="text-[18px] font-open-sana font-[400] text-center mt-4">Benefit from a workplace that&apos; s easily accessible with travel support.</div>
              </div>
              <div className="your-career-tech-card p-3">
                <div style={{ width: '50px', height: '50px', objectFit: 'contain' }}>
                  <img src={img6} alt="" />
                </div>
                <div className="text-[20px] font-poppins font-[700] text-center mt-4">Performance Rewards</div>
                <div className="text-[18px] font-open-sana font-[400] text-center mt-4">Earn recognition and rewards for your outstanding achievements.</div>
              </div>
            </div>
          </div>
          <div className="ms-5 justify-items-center mt-5 sm:mt-0">
            <div className="font-poppins font-[900] text-[44px] text-[#152C5B] mb-5">
              Your Career at <span className="text-[#314ED9]">Shivay Intelligence</span>
            </div>
            <div className="text-[18px] font-open-sana font-[400]">
              {`Joining Shivay Intelligence signifies more than a mere career choice—it's a step into a family that prioritizes your well-being and professional growth. Here, we
              don't just see our team as employees; we regard each member as an integral part of the Shivay family. Our core commitment is to nurture a workplace that celebrates
              innovation, mutual respect, and personal development.`}{' '}
              <br />
              <br />
              {`We believe that the well-being of our team is paramount to our success. That's why we cultivate an environment that supports not just the professional, but the
              personal aspirations of our members. At Shivay Intelligence, you'll collaborate with some of the most brilliant minds in the IT sector, tackling complex challenges
              while we ensure you have access to continuous learning opportunities to thrive.`}
              <br />
              <br />
              {`Our recognition of hard work extends beyond the office walls. We celebrate dedication with company retreats, foster unity through team-building activities, and ensure
              peace of mind with a comprehensive benefits package. At Shivay Intelligence, you're more than just a part of the machine; you're the heart of our operation, steering
              us towards a future where each success is shared and every milestone, a collective triumph.`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourCareerAtShivay;

import { Button } from 'antd';
import React from 'react';
import YourCareerAtShivay from '../components/YourCareerAtShivay';
import OurRecruitmentProcess from '../components/OurRecruitmentProcess';
import Career_1 from '../Images/carrer_1.png';
import JobOpening from '../components/JobOpening';

const Career = () => {
  return (
    <div className="px-3">
      <div className="container-xxl p-3">
        <div className="flex items-center gap-3">
          <div className="w-full md:w-1/2 text-center md:!text-left">
            <div className="text-[44px] font-[900] font-poppins">
              <span className="text-[#314ED9]">Join Our</span> Team
            </div>
            <div className="text-[18px] font-[400] font-open-sana">Work at the most dynamic and successful agency</div>
            {/* <Button
              type="text"
              className=" hover:!bg-[#314ed9] bg-[#152c5b] text-white text-base fw-bolder mt-2"
              style={{ height: 50 }}
              onClick={() => {
                window.scrollTo(2500, 2500);
              }}
            >
              Open Offerings
            </Button> */}
          </div>
          <div className="w-1/2 hidden md:block">
            <img src={Career_1} />
          </div>
        </div>
      </div>
      <YourCareerAtShivay />
      <OurRecruitmentProcess />
      {/* <JobOpening /> */}
    </div>
  );
};

export default Career;

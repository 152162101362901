import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../Images/shivay-logo.png';
import { FaFacebook, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import RichtextRender from './RichtextRender';
import Loader from './Loader/Loader';

const BlogPost = () => {
  const { type, blogId } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [blogDetails, setBlogDetails] = useState(null);

  const blogReducer = useSelector((state) => state.allBlogsReducer.allBlogs);

  const [suggestionBlog, setSuggestionBlog] = useState([]);

  useEffect(() => {
    if (blogReducer.success) {
      const data = blogReducer.data.items;
      const findBlog = data.find((item) => item.sys.id === blogId);
      const filter = data.filter((post) => post.metadata.tags.some((tag) => tag.sys.id === type));
      setSuggestionBlog(filter.slice(0, 3));
      if (findBlog) {
        setBlogDetails(findBlog);
      } else {
        navigate(`blogs?type=${type}`);
      }
    }
  }, [blogReducer, blogId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {blogDetails && (
        <div className="">
          <div className="p-10 font-poppins font-bold md:leading-[57px] leading-[45px] md:text-[38px] text-[28px] bg-[#3333330D] text-[#152C5B]">
            <div className="container">{blogDetails.fields.title}</div>
          </div>
          <div className="p-10 container">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="">
                <div className="text-[#152C5B] mb-3 text-[20px] font-medium">{type}</div>
                <div className="mb-3">{blogDetails.fields.metaDescription}</div>
                <div className="flex gap-4 items-center mb-3">
                  <div>
                    <img src={logo} alt="Logo" className={`w-[50px] h-[50px]  `} />
                  </div>
                  <div className="text-[#152C5B] font-bold text-[20px] ">Shivay Intelligence</div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mb-3">
                  <div className=" text-[#152C5B] font-semibold text-[20px]">
                    <div>Published On</div>
                    <div>{moment(blogDetails.fields.createdAt).format('ll')}</div>
                  </div>
                  <div className="">
                    <div className="flex gap-4 items-center">
                      <div className="font-bold text-[#152C5B] cursor-pointer ">SHARE</div>
                      <div className="font-bold text-[#152C5B] cursor-pointer text-xl ">
                        <FaFacebook />
                      </div>
                      <div className="font-bold text-[#152C5B] cursor-pointer text-xl ">
                        <FaInstagram />
                      </div>
                      <div className="font-bold text-[#152C5B] cursor-pointer text-xl ">
                        <FaLinkedinIn />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <img src={blogDetails.fields.coverImage.fields.file.url} alt="CoverImage" className="w-full h-full object-contain rounded-lg" />
              </div>
            </div>
          </div>
          <div className="p-10 container">
            <div className="flex flex-col lg:flex-row gap-5">
              {/* content */}
              <div className="">
                <div>
                  <RichtextRender content={blogDetails.fields.descriptions} />
                </div>
              </div>
              <div className="lg:w-[750px] h-fit bg-[#E1F0FD] p-4 rounded-xl shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)]">
                <img src={logo} alt="Logo" className="m-auto mb-3" />
                <div className="mb-3 font-poppins font-bold  md:text-[28px] text-[22px] text-center  text-[#152C5B]">Shivay Intelligence</div>
                <div className="text-[16px] font-normal leading-[26px] ">
                  The Shivay Intelligence Team comprises a group of passionate tech enthusiasts and seasoned analysts. With a collective experience spanning decades in the
                  information technology sector, our experts bring together insights from the cutting edge of tech research and development. Our mission is to illuminate the path
                  of digital transformation, decode the complexities of the tech world, and bring the pulse of innovation to our dedicated readers.
                </div>
                <div className="flex justify-center">
                  <Button className="my-3  text-white hover:!text-white h-[40px] md:h-[55px] border-2 hover:!bg-[#152C5B] bg-[#152C5B]  font-bold text-base md:text-lg px-8 md:px-10  rounded-xl ">
                    Contact Us
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-10 container">
            <div className="text-[#152C5B] font-bold text-[20px] text-center">Expert Insights for Digital Product Development</div>
            <div className="my-3 text-center">
              We at Shivay Intelligence are passionate about discussing recent technologies and applications. We constantly write blogs and articles associated with the field of
              technology. So, don't miss our detailed and insightful write-ups. You'll find all our latest blogs and blog updates here.
            </div>
            <div className="my-3">
              <div className="container grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-3 ">
                {suggestionBlog.map((item) => {
                  return (
                    <div
                      key={item.sys.id}
                      className="w-full flex-wrap rounded-2xl shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)] bg-white cursor-pointer"
                      onClick={() => {
                        navigate(`/blog/${type}/${item.sys.id}`);
                      }}
                    >
                      <img src={item.fields.coverImage.fields.file.url} alt="blog Image" className="rounded-t-2xl h-[180px] sm:h-[250px] w-full" />
                      <div className="p-3">
                        <div className="text-[#152C5B] font-poppins md:text-[24px] text-[18px] font-bold">{item.fields.title}</div>
                        <div className="text-[15px] md:text-[18px] font-normal my-3">{item.fields.metaDescription}</div>
                        <div className="text-[15px] md:text-[18px] font-normal my-3">{item.fields.createdAt}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Button
                  type="text"
                  className="hover:!bg-[#314ed9] bg-[#152c5b] text-white  fw-bolder"
                  style={{ height: 50, width: 250 }}
                  onClick={() => {
                    navigate(`/blogs?type=${type}`);
                  }}
                >
                  View More Blogs
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogPost;

import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import img1 from '../Images/Untitled-1.png';
import img2 from '../Images/Untitled-11.png';
import img3 from '../Images/Untitled-111.png';
import img4 from '../Images/Untitled-1111.png';
import img5 from '../Images/Untitled-11111.png';
import img6 from '../Images/Untitled-111111.png';

const OurCoreStrengths = () => {
  const [hoverId, setHoverId] = useState(1);

  return (
    <div className="mt-3 md:mt-5 pt-5">
      <div className="container-xxl">
        <div className="font-poppins text-[44px] font-[900] mb-[20px]">
          Our Core <span className="text-[#314ed9]">Strengths</span>
        </div>
        <p className="font-open-sana text-[18px] font-[400] ">
          Our experienced guides in brand development ensure a trustworthy path to your dream destination. Wondering why you should choose us? Join the ranks of forward-thinking
          digital trendsetters who opt for our enterprise expertise solutions for their brand development.
        </p>
      </div>
      <div className="grid container-xxl lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 mt-5 gap-5">
        <div className="grid justify-content-center">
          <div className="md:mt-4 mt-2">
            <Button type="text d-flex align-items-center" className="ourCoreStrengthButton bg-[#eaebed]" onMouseEnter={() => setHoverId(1)}>
              1<Divider className="text-white" type="vertical" style={{ background: 'white', height: 50 }} />
              <span className="ourCoreStrengthsButtonText">
                Expert
                <br />
                Developers
              </span>
            </Button>
          </div>
          <div className="md:mt-4 mt-2">
            <Button type="text d-flex align-items-center" className="ourCoreStrengthButton bg-[#eaebed]" onMouseEnter={() => setHoverId(2)}>
              2<Divider className="text-white" type="vertical" style={{ background: 'white', height: 50 }} />
              <span className="ourCoreStrengthsButtonText">
                Assured
                <br />
                Satisfaction
              </span>
            </Button>
          </div>
          <div className="md:mt-4 mt-2">
            <Button type="text d-flex align-items-center" className="ourCoreStrengthButton bg-[#eaebed]" onMouseEnter={() => setHoverId(3)}>
              3<Divider className="text-white" type="vertical" style={{ background: 'white', height: 50 }} />
              <span className="ourCoreStrengthsButtonText">
                Round-the-
                <br />
                Clock Support
              </span>
            </Button>
          </div>
        </div>

        <div className="hidden md:flex justify-content-center align-items-center">
          <img
            src={hoverId === 1 ? img1 : hoverId === 2 ? img2 : hoverId === 3 ? img3 : hoverId === 4 ? img4 : hoverId === 5 ? img5 : hoverId === 6 ? img6 : ''}
            alt=""
            style={{ height: 380 }}
          />
        </div>

        <div className="grid justify-content-center">
          <div className="md:mt-4 mt-2">
            <Button type="text d-flex align-items-center" className="ourCoreStrengthButton bg-[#eaebed]" onMouseEnter={() => setHoverId(4)}>
              4<Divider className="text-white" type="vertical" style={{ background: 'white', height: 50 }} />
              <span className="ourCoreStrengthsButtonText">Strong Ethics</span>
            </Button>
          </div>
          <div className="md:mt-4 mt-2">
            <Button type="text d-flex align-items-center" className="ourCoreStrengthButton bg-[#eaebed]" onMouseEnter={() => setHoverId(5)}>
              5<Divider className="text-white" type="vertical" style={{ background: 'white', height: 50 }} />
              <span className="ourCoreStrengthsButtonText">Creative Brunch</span>
            </Button>
          </div>
          <div className="md:mt-4 mt-2">
            <Button type="text d-flex align-items-center" className="ourCoreStrengthButton bg-[#eaebed]" onMouseEnter={() => setHoverId(6)}>
              6<Divider className="text-white" type="vertical" style={{ background: 'white', height: 50 }} />
              <span className="ourCoreStrengthsButtonText">
                Flexible Project
                <br />
                Plans
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCoreStrengths;

import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import logo from '../Images/Shivay_Intelligence.png';
import { HiMiniBars3 } from 'react-icons/hi2';

function Navbar2() {
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="w-full text-gray-700 bg-white dark:text-gray-200 dark:bg-gray-800 border-b-[1px] border-b-[#343050]">
      <div className="flex px-4 py-3 flex-col  md:items-center md:justify-between md:flex-row">
        <div className=" flex flex-row items-center justify-between">
          <NavLink to="/" className=" tracking-widest text-gray-900 uppercase rounded-lg dark:text-white focus:outline-none focus:shadow-outline">
            <img src={logo} alt="Logo" className={`w-[130px] h-[50px] `} />
          </NavLink>
          <button className="md:hidden rounded-lg focus:outline-none focus:shadow-outline" onClick={() => setOpen(!open)}>
            {open ? <IoMdClose className="w-6 h-6" /> : <HiMiniBars3 className="w-6 h-6" />}
          </button>
        </div>
        <nav className={`flex-col flex-grow my-3 md:m-0  md:flex md:justify-end md:flex-row ${open ? 'flex' : 'hidden'}`}>
          <div className="relative" onMouseLeave={() => setDropdownOpen(false)} onMouseEnter={() => setDropdownOpen(true)}>
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="flex flex-row gap-2 items-center w-full py-2 px-3 md:text-lg text-base text-black font-semibold text-left bg-transparent rounded-lg dark:bg-transparent dark:focus:text-white dark:hover:text-white dark:focus:bg-gray-600 dark:hover:bg-gray-600 md:w-auto  md:mt-0 md:ml-4 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
            >
              <span>Services</span>
              <FaChevronDown className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} />
            </button>
            {dropdownOpen && (
              <div className="absolute left-0 w-full origin-top-right rounded-md shadow-lg md:w-[400px]">
                <div className="px-2 py-2 bg-white rounded-md shadow dark:bg-gray-800">
                  {/* <NavLink
                    className="block py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
                    to={`/service/full-stack-application`}
                  >
                    Full Stack Applications
                  </NavLink> */}
                  <NavLink
                    className="block py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
                    to={`/service/web-application`}
                  >
                    Web Applications
                  </NavLink>
                  <NavLink
                    className="block py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
                    to={`/service/mobile-application`}
                  >
                    Mobile Applications
                  </NavLink>
                  <NavLink
                    className="block py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
                    to={`/service/database`}
                  >
                    Database consultancy
                  </NavLink>
                  <NavLink
                    className="block py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
                    to={`/service/cloud`}
                  >
                    AWS Cloud Management
                  </NavLink>
                  <NavLink
                    className="block py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
                    to={`/service/digital-marketing`}
                  >
                    Digital Marketing
                  </NavLink>
                  <NavLink
                    className="block py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
                    to={`/service/data-science`}
                  >
                    Data Science & Data Engineering Services
                  </NavLink>
                </div>
              </div>
            )}
          </div>

          <NavLink
            className="py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 md:ml-4 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
            to="/career"
          >
            Career
          </NavLink>
          <NavLink
            className="py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 md:ml-4 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
            to="/about-us"
          >
            About Us
          </NavLink>
          <NavLink
            className="py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 md:ml-4 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
            to="/portfolio"
          >
            Portfolio
          </NavLink>
          <NavLink
            className="py-2 px-3 md:text-lg text-base text-black font-semibold bg-transparent rounded-lg  md:mt-0 md:ml-4 hover:!text-gray-900 focus:!text-gray-900 hover:!bg-gray-200 focus:!bg-gray-200 focus:!outline-none focus:!shadow-outline"
            to="/blogs?type=web-application"
          >
            Blogs
          </NavLink>
          <NavLink
            className="py-2 px-3 md:text-lg text-base  font-semibold bg-transparent rounded-lg  md:mt-0 md:ml-4 hover:!text-black focus:!text-black hover:!bg-[#ffd700] focus:!bg-[#ffd700] focus:!outline-none focus:!shadow-outline !bg-[#fe7a36] text-white"
            to="/contact-us"
          >
            Contact
          </NavLink>
        </nav>
      </div>
    </div>
  );
}

export default Navbar2;

import React, { useEffect } from 'react';
import ContactInformation from '../components/ContactInformation';
import ContactUsBelowBanner from '../components/ContactUsBelowBanner';
import ContactUsFormContainer from '../components/ContactUsFormContainer';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ContactInformation />
      <ContactUsBelowBanner />
      <ContactUsFormContainer />
    </div>
  );
};

export default ContactUs;

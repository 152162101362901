import React from 'react';
import backGround from '../Images/12345.png';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const DriveYourTech = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/contact-us');
  };
  return (
    <div className="bg-[#152C5B]">
      <div
        className="container-xxl d-flex flex-column justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${backGround})`,
          width: '100%',
          backgroundSize: 'contain', // Ensures the image covers the entire div
          backgroundRepeat: 'no-repeat', // Prevents image from repeating
          backgroundPosition: 'center', // Centers the image
          height: '350px',
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="text-center driveYourTechHeader">Let&lsquo;s Drive Your Technology Forward</div>
          <div className="text-center driveYourTechText mt-4">Discover how our IT solutions can elevate your business today.</div>
          <div className="mt-4">
            <Button type="text" className="common-button-3 text-base  fw-bolder" style={{ height: 50, width: 210 }} onClick={handleClick}>
              Start Your Journey
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveYourTech;

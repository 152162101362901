import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';
import Footer from '../components/Footer';
import ContactUs from '../pages/ContactUs';
import Service from '../pages/Service';
import Career from '../pages/Career';
import Blogs from '../pages/Blogs';
import BlogPost from '../components/BlogPost';
import JobPage from '../components/JobPage';
import TagManager from 'react-gtm-module';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Navbar2 from '../components/Navbar2';
import { client } from '../helper/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogs, getErrorInBlogs, setBlogLoading } from '../action/Slicer/blogSlicer';
import WebApplication from '../pages/WebApplication.jsx';
import Portfolio from '../pages/Portfolio.jsx';
import PorthfolioList from '../pages/PorthfolioList.jsx';
import Helmet from 'react-helmet';

const RouterEntry = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageView',
        page: location.pathname,
        // Add more properties here as needed
      },
    });
  }, [location.pathname]);

  const dispatch = useDispatch();
  const blogReducer = useSelector((state) => state.allBlogsReducer.allBlogs);

  useEffect(() => {
    if (!blogReducer.data.success) {
      dispatch(setBlogLoading());
      const getBlogs = async () => {
        try {
          await client.getEntries().then((res) => {
            dispatch(getAllBlogs(res));
          });
        } catch (error) {
          console.log('Error', error);
          dispatch(getErrorInBlogs());
        }
      };
      getBlogs();
    }
  }, []);

  const RouteWithTitle = ({ element, title }) => (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {element}
    </>
  );

  return (
    <div>
      {/* <NavBar /> */}
      <Navbar2 />
      <Routes>
        <Route path="/" element={<RouteWithTitle title={'Shivay Intelligence'} element={<Home />} />} />
        <Route path="/about-us" element={<RouteWithTitle title={'About Us'} element={<AboutUs />} />} />
        <Route path="/contact-us" element={<RouteWithTitle title={'Contact Us'} element={<ContactUs />} />} />
        <Route path="/service" element={<RouteWithTitle title={'Service'} element={<Service />} />} />
        <Route path="/service/:type" element={<RouteWithTitle title={'Service'} element={<WebApplication />} />} />
        <Route path="/career" element={<RouteWithTitle title={'Career'} element={<Career />} />} />
        <Route path="/blogs" element={<RouteWithTitle title={'Blogs'} element={<Blogs />} />} />
        <Route path="/privacy-policy" element={<RouteWithTitle title={'Policy'} element={<PrivacyPolicy />} />} />
        <Route path="/blog/:type/:blogId" element={<RouteWithTitle title={'BlogPost'} element={<BlogPost />} />} />
        <Route path="/jobpage/:sk/:pk" element={<RouteWithTitle title={'JobPage'} element={<JobPage />} />} />
        <Route path="/portfolio" element={<RouteWithTitle title={'Portfolio'} element={<PorthfolioList />} />} />
        <Route path="/portfolio/:title" element={<RouteWithTitle title={'Portfolio'} element={<Portfolio />} />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default RouterEntry;

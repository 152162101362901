import React from 'react';
import { Avatar, Card, Carousel } from 'antd';
import backGround from '../Images/pattern.png';
import quoteImage from '../Images/Asset 5.svg';
import { carouselData } from '../helper/Constant';
import { CircleOutlined, CircleSharp } from '@mui/icons-material';
// const { Meta } = Card;

const OurCustomerLove = () => {
  return (
    <div
      className="mb-4"
      style={{
        backgroundImage: `url(${backGround})`,
        width: '100%',
        backgroundSize: 'cover', // Ensures the image covers the entire div
        backgroundRepeat: 'no-repeat', // Prevents image from repeating
        backgroundPosition: 'center', // Centers the image
        paddingTop: '5%',
        paddingBottom: '10%',
      }}
    >
      <div className="container-xxl mb-4">
        <div className="hidden md:flex justify-content-center align-items-center">
          <img src={quoteImage} alt="" style={{ width: 200, height: 200, objectFit: 'contain' }} />
        </div>
        <div>
          <h1 className="ourCustomerLove">
            Our Customers love <br />
            what we do!
          </h1>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Carousel
            autoplay={true}
            autoplaySpeed={2000}
            // dots={true}
            className="shadow-xl"
            dots={true}
            dotPosition="bottom"
            customPaging={(i) => <div className="custom-dot" />}
          >
            {carouselData.map((d, index) => {
              return (
                <Card key={index}>
                  <div className="d-flex gap-4">
                    <div>
                      <Avatar src={d.img} style={{ width: 100, height: 100 }} />
                    </div>
                    <div className="d-flex justify-center  flex-col">
                      <div className="ourCustomerCardTitle">{d.title}</div>
                      <div className="ourCustomerCardSubtitle">{d.subTitle}</div>
                    </div>
                  </div>
                  <p className="ourCustomerText mt-3">{d.text}</p>
                </Card>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default OurCustomerLove;

import React, { useEffect, useState } from 'react';
import point from '../Images/Group 10.png';
import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { actionGetJobsBySk, resetactionGetJobsBySk } from '../action/Slicer/getDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getDetails } from '../action/Functions';
import Loader from './Loader/Loader';

const JobPage = () => {
  const { sk, pk } = useParams();

  const dispatch = useDispatch();
  const listOfJobs = useSelector((state) => state.getdata.jobsBySK);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [details, setDetails] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (sk) {
      dispatch(resetactionGetJobsBySk());
    }
  }, [sk]);

  useEffect(() => {
    if (listOfJobs.length == 0 && isFirstTime) {
      setIsLoading(true);
      getDetails({
        pk: pk,
        sk: encodeURIComponent(`career#${sk}`), // decodeURIComponent() for decode
      })
        .then((response) => {
          setIsFirstTime(false);
          dispatch(actionGetJobsBySk(response.data));
        })
        .then(() => {
          setIsLoading(false);
        });
    }

    if (listOfJobs.length !== 0) {
      setDetails(listOfJobs);
    }
  }, [listOfJobs, sk, pk]);

const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/contact-us')
  }

  return (
    <>
      <div className="container">
        <div className="mt-5 font-['Open Sans'] font-[700] text-[18px] text-[#314ED9]">View all open role</div>
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div>
              <div className="mt-3 font-['Poppins'] font-[900] text-[44px] text-[#152C5B]">{details?.title}</div>
              <div className="mt-2 font-['Open Sans'] font-[700] text-[18px] text-[#333]">
                {details?.type}, {details?.location}
              </div>
            </div>
            <div className="mt-5">
              <div className="font-['Poppins'] font-[700] text-[24px] text-[#152C5B]">About Shivay Intelligence</div>
              <div className="px-5 pt-3">
                <p className="font-['Open Sans'] font-[400] text-[18px] text-[#333]my-2">
                  Shivay Intelligence IT Company is a pioneering force in the IT and technology services industry, with a mission to deliver cutting-edge solutions that redefine
                  the boundaries of innovation and efficiency. Located in the heart of India tech revolution, Ahmadabad, we are dedicated to empowering businesses worldwide through
                  our bespoke IT solutions, digital transformation services, and unparalleled expertise in emerging technologies. Our commitment to excellence has not only fueled
                  our rapid growth but has also cultivated enduring partnerships with global leaders across diverse sectors.
                </p>
                <p className="font-['Open Sans'] font-[400] text-[18px] text-[#333]my-2">
                  At the core of our success is VersaTech, a revolutionary platform that seamlessly integrates advanced AI, data analytics, and cloud services to provide businesses
                  with the agility, scalability, and intelligence they need to thrive in todays digital era. VersaTechs unmatched performance and adaptability have made it an
                  indispensable tool for companies aiming to enhance their operational efficiency, drive innovation, and gain a competitive edge in their respective industries. The
                  platforms growing popularity and the increasing demand for our services are a testament to our vision, our teams dedication, and our unwavering commitment to
                  pushing the boundaries of whats possible.
                </p>
              </div>
            </div>
            <div className="mt-5">
              <div className="font-['Poppins'] font-[700] text-[24px] text-[#152C5B]">About the Role</div>
              <div className="px-5 pt-3">
                {details?.role_description?.map((item, index) => {
                  return (
                    <p className="font-['Open Sans'] font-[400] text-[18px] text-[#333]my-2" key={index}>
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="mt-5">
              <div className="font-['Poppins'] font-[700] text-[24px] text-[#152C5B]">Responsibilities:</div>
              <div className="px-5 pt-3">
                {details?.responsibilities?.map((item, index) => {
                  return (
                    <div className="flex gap-x-5" key={index}>
                      <img src={point} alt="" />
                      <span className="font-['Open Sans'] font-[400] text-[18px] text-[#333]">{item}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-5">
              <div className="font-['Poppins'] font-[700] text-[24px] text-[#152C5B]">Responsibilities:</div>
              <div className="px-5 pt-3">
                {details?.requirements?.map((item, index) => {
                  return (
                    <div className="flex gap-x-5" key={index}>
                      <img src={point} alt="" />
                      <span className="font-['Open Sans'] font-[400] text-[18px] text-[#333]">{item}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-5">
              <div className="font-['Poppins'] font-[700] text-[24px] text-[#152C5B]">Join Us:</div>
              <div className="px-5 pt-3 font-['Open Sans'] font-[400] text-[18px] text-[#333]">
                At Shivay Intelligence IT Company, we dont just offer jobs; we offer career journeys. Here, Thats not my job is never heard because were all about exploring,
                learning, and growing together. If youre ready to take on new challenges and make a significant impact, wed love to hear from you.
              </div>
            </div>
            <div className="grid justify-center mb-5" >
              <Button className="mt-5 bg-[#152C5B] text-[white] h-[50px] w-[150px] rounded-xl" onClick={handleNavigate}>Contact Us</Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default JobPage;

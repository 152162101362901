import React from 'react';
import backgroundImage from '../Images/image 3.png';

const OurVision = () => {
  return (
    <div>
      <div className="grid container-xxl lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mt-5 mb-5">
        <div className='flex md:flex-row-reverse justify-center md:justify-start gap-5 mr-[0px] md:mr-[100px]'>
          <img src={backgroundImage} alt="" className="responsive-image" style={{ height: '400px', objectFit: 'fill' }} />
        </div>
        <div className="grid content-center">
          <h1 className="ourVisionHeader">Our Vision</h1>
          <p className="ourVisionText">
            At Shivay Intelligence, we envision a future where technology transcends traditional boundaries, empowering businesses and individuals alike with cutting-edge IT solutions. Our commitment lies in pioneering innovative approaches that redefine the landscape of digital transformation, driving growth, and efficiency across industries. By fostering a culture of excellence and collaboration, we aim to be at the forefront of the IT sector, contributing to a smarter, more connected world. Through our unwavering dedication to innovation and quality, Shivay Intelligence aspires to be the beacon of progress, setting new standards in the IT industry and beyond.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurVision;

import { Carousel } from 'antd';
import React from 'react';

import hardikkaneria from '../Images/team-1.png';
import darshanladani from '../Images/team-2.png';
import ankitasakariya from '../Images/team-3.png';
import dikshitlakhani from '../Images/team-4.png';
import yashsutariya from '../Images/team-5.png';
import krupalipadariya from '../Images/team-6.png';

const OurTeam = () => {
  let slides = [
    { img: krupalipadariya, name: 'Krupali Padariya', position: 'CEO' },
    { img: dikshitlakhani, name: 'Dikshit Lakhani', position: 'Full Stack Developer' },
    { img: ankitasakariya, name: 'Ankita Sakariya', position: 'Data Engineer' },
    { img: darshanladani, name: 'Darshan Ladani', position: 'Data Engineer' },
    { img: yashsutariya, name: 'Yash Sutariya', position: 'Full Stack Developer' },
    { img: hardikkaneria, name: 'Hardik Kaneria', position: 'WordPress Developer & UIUX Designer' },
  ];

  const responsiveSettings = [
    {
      breakpoint: 1200, // 1200 to down
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1008, // 1008 to down
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 800, // 800 to down
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  return (
    <div className="pt-5 pb-5" style={{ background: '#A1C6F8' }}>
      <div className="container-xxl team-header mb-5">Our Dedicated Team</div>
      <div>
        <Carousel
          autoplay={true}
          slidesToShow={3}
          autoplaySpeed={2000}
          infinite={true}
          centerMode={true}
          responsive={responsiveSettings}
          draggable
          dots={true}
          dotPosition="bottom"
          customPaging={(i) => <div className="custom-dot team-dot" />}
        >
          {slides.map((d, i) => {
            return (
              <div className="d-flex flex-column justify-content-center align-items-center" key={i}>
                <div>
                  <img src={d.img} alt="1" style={{ width: 400, height: 300, objectFit: 'contain' }} />
                </div>
                <div className="team-name-title">{d.name}</div>
                <div className="team-position-title">{d.position}</div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default OurTeam;

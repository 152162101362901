import React from 'react';
import { Card, Avatar, Carousel } from 'antd';
import quoteImage from '../Images/Asset 5.svg';
import { carouselData } from '../helper/Constant';

const WhatOurClientSay = () => {
  return (
    <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <div className="container-xxl whatOurClientSayMainContainer">
        <div className="whatOurClientSayHeaderContainer">
          <div className="align-self-end">
            <img src={quoteImage} alt="" style={{ width: 170, height: 130, objectFit: 'contain' }} />
          </div>
          <div className="whatOurClientSayTitle pb-10">
            What Our Clients
            <br /> say about us!
          </div>
        </div>

        <div className="whatOurClientSayCarouselContainer ps-2" style={{ maxWidth: '550px' }}>
          <div>
            <Carousel autoplay={true} autoplaySpeed={2000} dots={true} dotPosition="bottom" customPaging={(i) => <div className="custom-dot" />} className="shadow-xl">
              {carouselData.map((d) => {
                return (
                  <>
                    <Card>
                      <div className="d-flex gap-4">
                        <div>
                          <Avatar src={d.img} style={{ width: 100, height: 100 }} />
                        </div>
                        <div className="d-flex justify-content-center flex-col">
                          <div className="ourCustomerCardTitle">{d.title}</div>
                          <div className="ourCustomerCardSubtitle">{d.subTitle}</div>
                        </div>
                      </div>
                      <p className="ourCustomerText mt-3">{d.text}</p>
                    </Card>
                  </>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatOurClientSay;

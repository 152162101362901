import React, { useEffect, useState } from 'react';
import backGround from '../Images/aboutusImage1.png';
import { Button } from 'antd';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useNavigate, useParams } from 'react-router-dom';
import { serviceData } from '../helper/Constant';
import { useSelector } from 'react-redux';

function WebApplication() {
  const { type } = useParams();

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const [expanded, setExpanded] = useState(0); // Use state to track the expanded accordion

  const navigate = useNavigate();

  const handleClick = () => {
    window.open('https://shivayintelligence1.zohobookings.in/#/shivayintelligence', '_blank');
  };

  useEffect(() => {
    setLoading(true);
    if (type) {
      const findDetails = serviceData[type];
      if (findDetails) {
        setDetails(findDetails);
        setLoading(false);
      } else {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [type]);

  const blogReducer = useSelector((state) => state.allBlogsReducer.allBlogs);
  const [suggestionBlog, setSuggestionBlog] = useState([]);

  useEffect(() => {
    if (blogReducer.success) {
      const data = blogReducer.data.items;
      const filter = data.filter((post) => post.metadata.tags.some((tag) => tag.sys.id === type));
      setSuggestionBlog(filter.slice(0, 3));
    }
  }, [blogReducer, type]);

  return (
    <>
      {loading ? (
        <> </>
      ) : (
        <div>
          <div
            className="w-full h-full bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${backGround})`,
            }}
          >
            <div className="container md:py-28 py-16 ">
              <div className="flex flex-col gap-4">
                <div className="text-white font-poppins md:leading-[50px] leading-[40px] text-[40px] md:text-[50px]  font-semibold md:font-bold text-left w-full  ">
                  {details?.section1.title1}
                </div>
                <div className="text-[#FE7A36] font-poppins md:leading-[50px] leading-[40px] text-[40px] md:text-[50px]  font-semibold md:font-bold text-left w-full  ">
                  {details?.section1.title2}
                </div>
                <div className="text-white font-poppins md:leading-[28px] leading-[20px] text-[15px] md:text-[18px]  md:font-semibold font-normal text-left w-full  xl:w-[740px]">
                  {details?.section1.title_description}
                </div>
              </div>
              <Button className="mt-6 text-white h-[55px] border-0  font-semibold text-lg px-10 rounded-xl !bg-primary" onClick={handleClick}>
                Schedule Call
              </Button>
            </div>
          </div>

          <div className="container py-12 ">
            <div className="flex gap-4 flex-col lg:flex-row">
              {/* sec-1 */}
              <div className="lg:sticky lg:top-[50px] h-fit">
                <div>
                  <div className="font-poppins md:text-[36px] text-[28px] font-bold">{details?.section2.title}</div>
                  <div className="flex flex-col gap-2 md:gap-3 my-3">
                    {details?.section2.title_description.map((item, index) => {
                      return (
                        <div className="text-[15px] md:text-[18px] font-normal" key={index}>
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    className="my-3 text-[#152C5B] hover:!text-[#152C5B] h-[40px] md:h-[55px] border-2 hover:!border-[#152C5B] border-[#152C5B]  font-bold text-base md:text-lg px-8 md:px-10  rounded-xl "
                    onClick={handleClick}
                  >
                    Talk with the Expert
                  </Button>
                </div>
              </div>
              {/* sec-2 */}
              <div className="flex flex-col gap-4">
                {details?.section2.listOfServices.map((item, index) => {
                  return (
                    <div key={index} className="w-full p-8 rounded-2xl shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)] bg-white">
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-3 items-center">
                          <img src={item.img} alt="Technology Icon " />
                          <div className="font-poppins text-[24px] font-bold">{item.title}</div>
                        </div>
                        <div className="text-[15px] md:text-[18px] font-normal">
                          {item.description}
                          {!!item.more && (
                            <div className="mt-3">
                              <div dangerouslySetInnerHTML={{ __html: item.more }} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* <div className="container py-12 ">
            <div className="flex gap-4 flex-col">
              <div>
                <div className="font-poppins md:text-[36px] text-[28px] font-bold">
                  <span className="text-[#314ED9]">Innovation Ideation</span> Success Stories
                </div>
                <div className="text-[15px] md:text-[18px] font-normal my-3">
                  Discover how we translate strategic innovation into differentiated capabilities and accelerate bottom-line impacts for fast-growing enterprises and
                  digitally-native companies.
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
                {details?.section3.list_of_stories.map((item) => {
                  return (
                    <div className="p-8 rounded-2xl shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)] bg-white" key={item.id}>
                      <div className="text-[#152C5B] font-poppins md:text-[24px] text-[18px] font-bold">{item.title}</div>
                      <div>
                        <img src={item.image} alt="blog Image" className="rounded-t-2xl h-[200px] sm:h-[300px] w-full" />
                      </div>
                      <div className="text-[15px] md:text-[18px] font-normal my-3">
                        Discover &apos;Dog Diet Essentials&apos; - your ultimate guide to understanding your pet&apos;s nutritional needs. Learn
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div> */}

          <div className=" py-12  bg-[#DBE9FC]">
            <div className="flex gap-2 lg:!gap-10 flex-col lg:flex-row items-center justify-center container">
              <div className="">
                <div className="font-poppins md:text-[44px] text-[34px] font-normal">
                  Let&apos;s Create Big Empire <span className="text-[#314ED9] font-black">Together</span>
                </div>
                <div className="text-[15px] md:text-[18px] font-normal my-3">
                  Custom web application development is at the core of our specialization. We don&apos;t just build web apps; we create brand-enhancing solutions.
                </div>
              </div>
              <Button
                className="my-3 text-white hover:!text-white h-[40px] md:h-[55px] border-2 hover:!bg-[#152C5B] bg-[#152C5B]  font-bold text-base md:text-lg px-8 md:px-10  rounded-xl "
                onClick={handleClick}
              >
                Get a meeting with us
              </Button>
            </div>
          </div>

          {/*  */}
          <div className="container py-12 ">
            <div className="text-[#152C5B] font-poppins md:text-[28px] text-[22px] font-bold">Insights & News</div>
            <div className="my-2 md:my-3 text-[15px] md:text-[18px]">Get the latest news and technology updates to stay ahead.</div>
            <div className="my-3">
              <div className="container grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-3 ">
                {suggestionBlog.map((item) => {
                  return (
                    <div
                      key={item.sys.id}
                      className="w-full flex-wrap rounded-2xl shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)] bg-white cursor-pointer"
                      onClick={() => {
                        navigate(`/blog/${type}/${item.sys.id}`);
                      }}
                    >
                      <img src={item.fields.coverImage.fields.file.url} alt="blog Image" className="rounded-t-2xl h-[180px] sm:h-[250px] w-full" />
                      <div className="p-3">
                        <div className="text-[#152C5B] font-poppins md:text-[24px] text-[18px] font-bold">{item.fields.title}</div>
                        <div className="text-[15px] md:text-[18px] font-normal my-3">{item.fields.metaDescription}</div>
                        <div className="text-[15px] md:text-[18px] font-normal my-3">{item.fields.createdAt}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/*  */}
          <div className="container py-12 ">
            <div className="text-[#152C5B] font-poppins md:text-[28px] text-[22px] font-bold text-center">Frequently Asked Questions: Insights & Solutions</div>

            <div className="rounded-2xl bg-[#F7F7F7]  sm:p-8 my-3">
              {details?.section4.list_of_faq.map((faq, index) => (
                <div
                  key={index}
                  className="p-2"
                  onClick={() => {
                    if (index === expanded) {
                      setExpanded('');
                    } else {
                      setExpanded(index);
                    }
                  }}
                >
                  <AccordionItem key={index} title={faq.question} content={faq.answer} isOpen={expanded === index} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WebApplication;

const AccordionItem = ({ title, content, isOpen }) => {
  return (
    <div className="bg-white p-4 rounded-2xl  cursor-pointer flex gap-2 justify-between">
      <div>
        <div className="text-[#152C5B] mb-3 sm:text-[24px] text-[18px] font-bold leading-[28px]">{title}</div>
        <div className={`${isOpen ? 'block' : 'hidden'} sm:text-[18px] text-[14px]`}>{content}</div>
      </div>
      <div className="text-[24px]">{isOpen ? <FaCaretDown /> : <FaCaretRight />}</div>
    </div>
  );
};

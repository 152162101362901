import { Button } from 'antd';
import React from 'react';

const BigEmpire = () => {
  const handleClick = () => {
    window.open('https://shivayintelligence1.zohobookings.in/#/shivayintelligence', '_blank');
  };
  return (
    <div style={{ backgroundColor: '#E5EFFD' }} className="mt-5">
      <div className="container-xxl p-5 flex flex-col md:flex-row items-center gap-2">
        <div>
          <h4 className="bigEmpireTitle mb-4">
            Let’s Create Big Empire <span style={{ color: '#314ED9', fontWeight: 'bolder' }}>Together</span>
          </h4>
          <p className="bigEmpireText mb-4">{`Custom web application development is at the core of our specialization. We don't just build web apps; we create brand-enhancing solutions.`}</p>
          <h5 className="bigEmpireFooter">Selecting us will undoubtedly be your wisest choice.</h5>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 md:mt-0">
          <Button type="text" className="hover:!bg-[#314ed9] bg-[#152c5b] text-white text-base fw-bolder" style={{ height: 50, width: 150 }} onClick={handleClick}>
            Contact
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BigEmpire;

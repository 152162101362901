import React, { useState } from 'react';
import Loader from '../components/Loader/Loader';
import { portfolioList } from '../helper/Constant';
import { useNavigate } from 'react-router-dom';

function PorthfolioList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      <div className="w-full bg-cover bg-no-repeat bg-center border-b-[1px] border-b-[#343050]">
        <div className="px-6 xl:px-64 md:px-28  py-8">
          <div className="flex flex-col gap-3">
            <div className="text-[#314ED9] text-center font-poppins md:leading-[50px] leading-[40px] text-[35px] md:text-[40px]  font-bold  w-full ">
              Portfolio | Shivay Intelligence
            </div>
            <div className=" text-center  md:leading-[50px] leading-[40px] text-[15px] md:text-[24px]  font-normal  w-full ">
              Translating your ideas from thoughts to market products.
            </div>
          </div>
          {/* <div className="mt-4">
          <div className="text-sm md:text-lg mb-4 font-semibold text-center text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <div className="sm:hidden ">
              <label htmlFor="tabs" className="sr-only">
                {pageType}
              </label>
              <select
                id="tabs"
                value={pageType}
                onChange={(e) => {
                  navigate(`/blogs?type=${e.target.value}`);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value={'full-stack-application'}>Full Stack Applications</option>
                <option value={'web-application'}>Web Applications</option>
                <option value={'mobile-application'}>Mobile Applications</option>
                <option value={'database'}>Database</option>
                <option value={'cloud'}>Cloud</option>
                <option value={'digital-marketing'}>Digital Marketing</option>
                <option value={'data-science'}>data-science</option>
              </select>
            </div>
            <ul className="hidden sm:flex flex-wrap justify-center -mb-px ">
              <li className="me-2">
                <NavLink
                  to="/blogs?type=full-stack-application"
                  className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${
                    pageType === 'full-stack-application' ? 'text-[#fe7a36]' : 'text-gray-600'
                  }`}
                >
                  Full Stack Applications
                </NavLink>
              </li>
              <li className="me-2">
                <NavLink
                  to="/blogs?type=web-application"
                  className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'web-application' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                >
                  Web Applications
                </NavLink>
              </li>
              <li className="me-2">
                <NavLink
                  to="/blogs?type=mobile-application"
                  className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'mobile-application' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                  aria-current="page"
                >
                  Mobile Applications
                </NavLink>
              </li>
              <li className="me-2">
                <NavLink
                  to="/blogs?type=database"
                  className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'database' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                >
                  Database
                </NavLink>
              </li>
              <li className="me-2">
                <NavLink
                  to="/blogs?type=cloud"
                  className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'cloud' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                >
                  Cloud
                </NavLink>
              </li>
              <li className="me-2">
                <NavLink
                  to="/blogs?type=digital-marketing"
                  className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'digital-marketing' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                >
                  Digital Marketing
                </NavLink>
              </li>
              <li className="me-2">
                <NavLink
                  to="/blogs?type=data-science"
                  className={`inline-block p-3 border-transparent rounded-t-lg hover:!text-[#fe7a36] ${pageType === 'data-science' ? 'text-[#fe7a36]' : 'text-gray-600'}`}
                >
                  data-science
                </NavLink>
              </li>
            </ul>
          </div>
        </div> */}
        </div>
      </div>
      <div className="px-6 xl:px-64 py-12 ">
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div className="container grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-3 ">
              {portfolioList.map((item) => {
                return (
                  <div
                    className="bg-white shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)] rounded-2xl  cursor-pointer p-3 max-w-xs h-[285px] flex flex-col justify-between"
                    key={item.project_name}
                    onClick={() => {
                      navigate(`/portfolio/${item.project_path}`);
                    }}
                  >
                    <img src={item.list_image} alt={item.project_path} className="rounded-t-2xl max-h-[200px] w-full object-cover" />
                    <h3 className="text-xl font-semibold leading-tight mt-2 line-clamp-2 text-center">{item.project_name}</h3>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PorthfolioList;

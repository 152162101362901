import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import backgroundImage from '../Images/Asset 9.svg';
import backGround from '../Images/ready-set-bg.png';

const ReadyToSet = () => {
  const codeSnippet = [
    { text: 'pip install shivay_intelligence\n' },
    { text: 'installing......................100% completed\n' },
    { text: '> Shivay Intelligence.py\n' },
    { text: '$ Connecting to Shivay Intelligence Web Portal\n' },
    { text: 'Connecting...\n', delay: 1000 },
    { text: '$ Loading cutting-edge Web Development solutions\n' },
    { text: 'Loading...\n', delay: 1000 },
    { text: '$ Syncing with Advanced Cloud Technologies\n' },
    { text: 'Syncing...\n', delay: 1000 },
    { text: '$ Extracting data with Precision Web Scraping\n' },
    { text: 'Extracting...\n', delay: 1000 },
    { text: 'Run Successful...\n', delay: 1000 },
    { text: 'All systems go! Welcome to a new era of digital innovation with us!\n' },
    { text: 'Shivay Intelligence' },
  ];

  const [typedText, setTypedText] = useState('');
  const [lastLineText, setLastLineText] = useState('');
  const [lastLineBold, setLastLineBold] = useState(false); // New state to manage font weight of lastLineText
  const [currentLine, setCurrentLine] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);

  useEffect(() => {
    if (currentLine < codeSnippet.length) {
      const line = codeSnippet[currentLine].text;
      const delay = codeSnippet[currentLine].delay || 0; // Default to 0 delay if not specified

      if (currentCharIndex < line.length) {
        setTimeout(() => {
          if (currentLine === codeSnippet.length - 1) {
            setLastLineText((prev) => prev + line.charAt(currentCharIndex));
            // When starting to type the last line, schedule the bold effect
            if (currentCharIndex === 0) {
              setTimeout(() => {
                setLastLineBold(true);
              }, 2000); // Set the last line to bold after 2 seconds
              setTimeout(() => {
                setLastLineBold(null);
              }, 4000);
            }
          } else {
            const typed = typedText + line.charAt(currentCharIndex);
            setTypedText(typed);
          }
          setCurrentCharIndex(currentCharIndex + 1);
        }, 30); // Typing speed
      } else {
        if (currentLine < codeSnippet.length - 1) {
          // Add a newline for all but the last line
          // setTypedText((prev) => prev + '\n');
        }
        setTimeout(() => {
          setCurrentLine(currentLine + 1);
          setCurrentCharIndex(0);
        }, delay);
      }
    }
  }, [currentLine, currentCharIndex, codeSnippet]);

  const handleClick = () => {
    window.open('https://shivayintelligence1.zohobookings.in/#/shivayintelligence', '_blank');
  };

  return (
    <div className="container-xxl readySetCodeMainContainer  mt-5">
      {lastLineBold === null ? (
        <div className="lg:w-[55%] md:w-[55%] sm:w-[100%]">
          <img src={backgroundImage} alt="" style={{ objectFit: 'contain' }} className="bannerImageReadyToGo h-[350px]  md:h-[600px] mx-auto" />
        </div>
      ) : (
        <div className="h-[400px] lg:h-[400px] md:h-[480px] lg:w-[50%] md:w-[50%] sm:w-[100%]">
          <div className="py-2 w-[100%] bg-[#D3D7E7] px-3 flex items-center rounded-t-xl">
            <div className="mx-1 h-[15px] w-[15px] rounded-full bg-[#FE5F57]"></div>
            <div className="mx-1 h-[15px] w-[15px] rounded-full bg-[#FEBC2E]"></div>
            <div className="mx-1 h-[15px] w-[15px] rounded-full bg-[#27C840]"></div>
          </div>
          <div className="px-3 py-2 h-[100%] w-[100%] bg-[#333] rounded-b-xl d-block whitespace-pre-line">
            <div className="hero-section">
              <div className="typing-effect text-[#77FFC3] text-md font-bold">
                {typedText}
                {/* Apply conditional styling for lastLineText based on lastLineBold state */}
                <span
                  style={{
                    // fontSize: '50px',
                    transition: lastLineBold ? 'all 0.5s' : '',
                    color: '#BEE1FF',
                    fontSize: lastLineBold ? '35px' : '', // Conditional font weight
                  }}
                >
                  {lastLineText}
                </span>
                <span className="cursor"></span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="lg:w-[45%] md:w-[45%] sm:w-[100%]"
        style={{
          backgroundImage: `url(${backGround})`,

          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundRepeat: 'no-repeat', // Prevents image from repeating
          backgroundPosition: 'center', // Centers the image
          padding: '40px',
        }}
      >
        <div className="font-poppins text-[44px] font-[900] mb-[20px] break-words">
          Ready, Set, <span style={{ color: '#FE7A36' }}>Code.</span>
        </div>
        <div className="font-[700] text-[24px] leading-snug mb-[20px] font-open-sana">
          We are your comprehensive AI and digital development ally, spanning from inception to deployment and beyond.
        </div>
        <p className="text-[18px] font-[400] leading-snug mb-[20px] font-open-sana">
          Empower your business with Shivay Intelligence – Your gateway to cutting-edge AI-ML solutions, cloud applications, and bespoke web development for transformative digital
          success.
        </p>
        <div className="mt-4">
          <Button type="text" className="common-button text-base fw-bolder font-open-sana" style={{ height: 50 }} onClick={handleClick}>
            Get Your Project Ready
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReadyToSet;

import React from 'react';
import frame from '../Images/Frame 1000001856.png';
import Group1 from '../Images/Group1.png';
import Group2 from '../Images/Group2.png';
import Group3 from '../Images/Group3.png';
import Group4 from '../Images/Group4.png';
import Group5 from '../Images/Group5.png';

const OurRecruitmentProcess = () => {
  return (
    <div className="my-[80px]">
      <div className="container-xxl">
        <div className="text-[32px] font-poppins font-[700] text-center mb-5 ">
          Our Recruitment <span style={{ color: '#314ed9' }}>Process</span>
        </div>
        <div className="grid-cols-3 mt-5 hidden lg:grid">
          <div>
            <div className="mx-[100px] text-center bg-[#F6F7F8] px-3 py-4 rounded-xl font-open-sana text-[18px] font-[700]">
              Open Vacancy: The company identifies a need for new staff due to growth or departures.
            </div>
            <div className="mx-[100px] text-center bg-[#F6F7F8] px-3 py-4 rounded-xl font-open-sana text-[18px] font-[700] mt-[100px]">
              Interview: Select candidates are interviewed to gauge their skills and company fit.
            </div>
          </div>
          <div className="">
            <img src={frame} />
            <div className="text-center bg-[#F6F7F8] px-3 py-4 rounded-xl font-open-sana text-[18px] font-[700] mt-[50px]">
              Job Offer: The standout interviewee is extended an offer to join the organization.
            </div>
          </div>
          <div>
            <div className="mx-[100px] text-center bg-[#F6F7F8] px-3 py-4 rounded-xl font-open-sana text-[18px] font-[700]">
              Recruiting: Candidates apply by presenting their resumes that highlight their fit for the role.
            </div>
            <div className="mx-[100px] text-center bg-[#F6F7F8] px-3 py-4 rounded-xl font-open-sana text-[18px] font-[700] mt-[100px]">
              HR Review: HR professionals screen applicants to find those who meet the job criteria.
            </div>
          </div>
        </div>
        {/* ...................... medium size...................... */}
        <div className=" hidden md:grid lg:hidden container-xl mt-5">
          <div className="grid grid-cols-2">
            <div className="grid justify-content-center">
              <img src={Group1} />
            </div>
            <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center me-[100px] bg-[#F6F7F8]">
              Open Vacancy: The company identifies a need for new staff due to growth or departures.
            </div>
          </div>
          <div className="grid grid-cols-2 mt-5">
            <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center ms-[50px] me-[50px] bg-[#F6F7F8]">
              Open Vacancy: The company identifies a need for new staff due to growth or departures.
            </div>
            <div className="grid justify-content-center">
              <img src={Group2} />
            </div>
          </div>
          <div className="grid grid-cols-2 mt-5">
            <div className="grid justify-content-center">
              <img src={Group3} />
            </div>
            <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center me-[100px] bg-[#F6F7F8]">
              Open Vacancy: The company identifies a need for new staff due to growth or departures.
            </div>
          </div>
          <div className="grid grid-cols-2 mt-5">
            <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center ms-[50px] me-[50px] bg-[#F6F7F8]">
              Open Vacancy: The company identifies a need for new staff due to growth or departures.
            </div>
            <div className="grid justify-content-center">
              <img src={Group4} />
            </div>
          </div>
          <div className="grid grid-cols-2 mt-5">
            <div className="grid justify-content-center">
              <img src={Group5} />
            </div>
            <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center me-[100px] bg-[#F6F7F8]">
              Open Vacancy: The company identifies a need for new staff due to growth or departures.
            </div>
          </div>
        </div>
        {/* ......................small size...................... */}
        <div className="grid md:hidden">
          <div className="grid grid-cols-1">
            <div className="mt-5">
              <div className="grid justify-center">
                <img src={Group1} />
              </div>
              <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center bg-[#F6F7F8] mt-4 mx-[50px] py-3">
                Open Vacancy: The company identifies a need for new staff due to growth or departures.
              </div>
            </div>
            <div className="mt-5">
              <div className="grid justify-center">
                <img src={Group2} />
              </div>
              <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center bg-[#F6F7F8] mt-4 mx-[50px] py-3">
                Open Vacancy: The company identifies a need for new staff due to growth or departures.
              </div>
            </div>
            <div className="mt-5">
              <div className="grid justify-center">
                <img src={Group3} />
              </div>
              <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center bg-[#F6F7F8] mt-4 mx-[50px] py-3">
                Open Vacancy: The company identifies a need for new staff due to growth or departures.
              </div>
            </div>
            <div className="mt-5">
              <div className="grid justify-center">
                <img src={Group4} />
              </div>
              <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center bg-[#F6F7F8] mt-4 mx-[50px] py-3">
                Open Vacancy: The company identifies a need for new staff due to growth or departures.
              </div>
            </div>
            <div className="mt-5">
              <div className="grid justify-center">
                <img src={Group5} />
              </div>
              <div className="rounded-xl px-3 font-open-sana text-[18px] font-[700] grid content-center bg-[#F6F7F8] mt-4 mx-[50px] py-3">
                Open Vacancy: The company identifies a need for new staff due to growth or departures.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurRecruitmentProcess;

import React from 'react';
import ReadyToSet from '../components/ReadyToSet';
import FutureIsHere from '../components/FutureIsHere';
import OurCoreStrengths from '../components/OurCoreStrengths';
import DigitalFuture from '../components/DigitalFuture';
import TechStack from '../components/TechStack';
import BigEmpire from '../components/BigEmpire';
import OurCustomerLove from '../components/OurCustomerLove';

const Home = () => {
  return (
    <div>
      <ReadyToSet />
      <FutureIsHere />
      <OurCoreStrengths />
      <DigitalFuture />
      <TechStack />
      <BigEmpire />
      <OurCustomerLove />
    </div>
  );
};

export default Home;

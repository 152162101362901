import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const FAQComponent = () => {
  const [expanded, setExpanded] = useState(0); // Use state to track the expanded accordion

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // FAQs data
  const faqs = [
    {
      key: '1',
      question: 'What AI and ML services does Shivay Intelligence offer to enhance business operations?',
      answer:
        'Our AI and ML services encompass a broad range of solutions including predictive analytics, natural language processing, and machine learning model development to streamline decision-making and automate processes, tailored to your specific business needs.',
    },
    {
      key: '2',
      question: `How can Shivay Intelligence's Data Analytics services help in understanding my company's data better?`,
      answer:
        'We specialize in transforming raw data into actionable insights through advanced data analytics techniques. Our services include data warehousing, big data processing, and visualization tools to help you make informed decisions.',
    },
    {
      key: '3',
      question: 'Can Shivay Intelligence assist with the end-to-end process of website development? ',
      answer:
        'Absolutely, our website development services cover everything from conceptual design and user experience to full-stack development and maintenance, ensuring a responsive, SEO-friendly, and engaging online presence for your business.',
    },
    {
      key: '4',
      question: 'What does Cloud Consultancy involve, and how can it benefit my business infrastructure?',
      answer:
        'Our Cloud Consultancy provides comprehensive guidance on cloud strategy, deployment, and management to optimize your IT infrastructure for scalability, security, and cost-efficiency. We help you harness the power of cloud computing to meet your business objectives.',
    },
    {
      key: '5',
      question: 'How does Automated Digital Marketing from Shivay Intelligence work to improve my marketing efforts?',
      answer:
        'Our Automated Digital Marketing services utilize cutting-edge technology to personalize customer interactions, streamline campaign management, and analyze marketing data. This leads to more efficient marketing operations and improved ROI for your campaigns.',
    },
    // Add more FAQs as needed
  ];

  return (
    <div className="w-full md:w-3/4 m-auto p-4 mb-4" style={{ backgroundImage: 'url("your-background-image-url")', height: 'auto' }}>
      <div className="text-[#152C5B] md:text-[32px] text-[26px] font-poppins font-[700] my-5 text-center">Frequently Asked Questions: Insights & Solutions</div>
      <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#F7F7F7', height: 'auto', borderRadius: '20px', padding: '5%' }}>
        <div className="w-100" style={{ maxWidth: '90%', overflow: 'hidden' }}>
          {faqs.map((faq, index) => (
            <div key={index} className="p-2">
              <Accordion className="rounded-xl p-3" expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className=" md:text-[24px] text-[20px] font-open-sana font-[700] leading-7 text-[#152c5b]"
                >
                  {faq.question}
                </AccordionSummary>
                <AccordionDetails className="text-[18px] font-open-sana font-[400]">{faq.answer}</AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;

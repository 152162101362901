import React from 'react';
import backGround from '../Images/HeaderBanners_11.png';

const WhoAreWe = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${backGround})`,
        width: '100%',
        backgroundSize: 'cover', // Ensures the image covers the entire div
        backgroundRepeat: 'no-repeat', // Prevents image from repeating
        backgroundPosition: 'center', // Centers the image
        height: '100%',
      }}
    >
      <div className="container-xxl">
        <div className="whoAreWeTitle" style={{ paddingTop: '15%' }}>
          Who are we
        </div>
        <div className="whoAreWeText" style={{ paddingBottom: '15%' }}>
          At Shivay Intelligence, we are the architects of tomorrow&apos;s technological landscape, dedicated to harnessing the power of Artificial Intelligence (AI), Machine Learning
          (ML), and cutting-edge data management techniques to redefine industries and elevate businesses to new heights. Our expertise extends beyond conventional boundaries,
          encompassing a comprehensive suite of services including AI solutions, ML algorithms, data analytics, and website development, among others.
        </div>
      </div>
    </div>
  );
};

export default WhoAreWe;

import React from 'react';
// import backGround1 from '../Images/serviceHeaderBackground.png';
import belowImage from '../Images/serviceheaderbewlo.png';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const ServiceHeaderComponent = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact-us');
  };
  return (
    <div>
      <div className=" d-flex flex-column justify-content-center align-items-center bg-[#F6F7F8] px-3 ">
        <div className="mt-5 mb-2 text-[#152c5b] lg:text-[44px] md:text-[30px]  text-[24px] font-[900] font-poppins text-center w-full md:w-3/4  ">
          Our expertise in strategy, transformation, AI, data, and technology unlocks extraordinary{' '}
          <span className="relative">
            results.
            <div className="absolute bottom-[-10px] left-0">
              <img src={belowImage} alt="" />
            </div>
          </span>
        </div>
        <div className="text-[18px] font-open-sana font-[700] leading-7 text-center my-5 w-full md:w-3/4 ">
          We believe that challenges are best solved with a team. A team you trust is even better. Let’s work together from vision through execution with trust and authenticity to
          achieve your next goal.
        </div>
        <div className="d-flex justify-content-center">
          <Button type="text" className="hover:!bg-[#314ed9] bg-[#152c5b] text-white text-base  fw-bolder" style={{ height: 50 }} onClick={handleClick}>
            Find Your Solution
          </Button>
        </div>
      </div>
      <div className="">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230">
          <path
            fill="#F6F7F8"
            fillOpacity="1"
            d="M0,160L30,165.3C60,171,120,181,180,165.3C240,149,300,107,360,96C420,85,480,107,540,122.7C600,139,660,149,720,154.7C780,160,840,160,900,144C960,128,1020,96,1080,90.7C1140,85,1200,107,1260,101.3C1320,96,1380,64,1410,48L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default ServiceHeaderComponent;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './action/store';
import TagManager from 'react-gtm-module';
const root = ReactDOM.createRoot(document.getElementById('root'));
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './index.css';

const tagManagerArgs = {
  gtmId: 'GTM-WKBF438L', // Replace GTM-XXXXXXX with your GTM ID.
};

TagManager.initialize(tagManagerArgs);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

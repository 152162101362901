import React from 'react';
import backGround from '../Images/aboutusImage1.png';

const YourInnovationPartner = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${backGround})`,
        width: '100%',
        backgroundSize: 'cover', // Ensures the image covers the entire div
        backgroundRepeat: 'no-repeat', // Prevents image from repeating
        backgroundPosition: 'center', // Centers the image
        height: '100%',
      }}
    >
      <div className="container-xxl">
        <div className="yourInnovationPartnerTitle">Your Innovation Partner</div>
        <div className="yourInnovationPartnerText">
          We thrive on harnessing the potential of cutting-edge technology for established corporations and innovative startups alike.
        </div>
      </div>
    </div>
  );
};

export default YourInnovationPartner;

import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  window.scrollTo(0, 0);
  return (
    <div className="privacy-policy">
      <h1>
        <strong>Welcome to Shivay Intelligence</strong>
      </h1>
      <br />
      <p>
        Your privacy is critically important to us, and we are committed to protecting your personal data. This privacy policy will inform you how we handle your personal data when
        you visit our website and interact with us, including any data you may provide through our site when you contact us, sign up for our newsletter, or request services.
      </p>
      <br />
      <h2>1. Important Information and Who We Are</h2>
      <p>This privacy policy aims to provide you information on how Shivay Intelligence collects and processes your personal data through your use of this website.</p>
      <p>
        <strong>Controller</strong>
        <br />
        Shivay Intelligence is the controller and responsible for your personal data collected through the <a href="/">https://shivayintelligence.com</a> website.
      </p>
      <p>
        <strong>Contact Details</strong>
        <br />
        If you have any questions about this privacy policy or our privacy practices, please contact us at:
        <br />
        Email: admin@shivayintelligence.com
      </p>
      <br />
      <h2>2. The Data We Collect About You</h2>
      <p>
        We may collect, use, store, and transfer the following kinds of personal data about you:
        <br />
        Identity Data includes first name and last name.
        <br />
        Contact Data includes email address and telephone numbers.
      </p>
      <br />
      <h2>3. How We Use Your Personal Data</h2>
      <p>
        We will only use your personal data when the law allows us to. Most commonly, we use your personal data in the following circumstances:
        <br />
        To register you as a new contact or client.
        <br />
        To manage and fulfill your requests.
        <br />
        To send you information about our services that may be of interest to you, based on your preferences.
        <br />
        We do not share your personal data with any third party for marketing purposes without your express consent.
      </p>
      <br />
      <h2>4. Data Security</h2>
      <p>
        We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way. We limit access to
        your personal data to those employees and agents who have a business need to know such data. They will only process your personal data on our instructions and they are
        subject to a duty of confidentiality.
      </p>
      <br />
      <h2>5. Data Retention</h2>
      <p>
        We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal,
        regulatory, tax, accounting, or reporting requirements.
      </p>
      <br />
      <h2>6. Your Legal Rights</h2>
      <p>
        Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, or
        transfer of your personal data, as well as the right to withdraw consent to processing and the right to object to processing.
      </p>
      <p>
        <br />
        <strong>How to Exercise Your Rights</strong>
        <br />
        If you wish to exercise any of the rights set out above, please{' '}
        <button className="border-0 bg-transparent" onClick={() => navigate('/contact-us')}>
          <strong>contact us.</strong>
        </button>
      </p>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';
import footerLogo from '../Images/Logo.png';
import email from '../Images/Asset 23.png';
import phone from '../Images/Asset 24.png';
import location from '../Images/Asset 25.png';
// import facebook from '../Images/Asset 26.png';
import instagram from '../Images/Asset 27.png';
import linkedin from '../Images/Asset 28.png';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
  const navigate = useNavigate();

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="bg-[#152C5B]">
      <div className="grid container-xxl grid-cols-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2  md:gap-5 gap-3 sm:px-5">
        <div className="logo mt-5 flex flex-col gap-4 ">
          <div className="">
            <img src={footerLogo} alt="" />
          </div>
          <div className="sm:mt-4 mt-1">
            <span className="footerText">
              Shivay Intelligence: Pioneering in
              <br /> AI, ML, and serverless tech. We
              <br /> deliver secure, efficient, and
              <br /> engaging tech solutions for
              <br /> business transformation
            </span>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <img src={email} alt="" />
              <span className="footerEmail ms-4 cursor-pointer">hr@shivayintelligence.com</span>
            </div>
            <div className="mt-4 d-flex align-items-center">
              <img src={phone} alt="" />
              <span className="footerEmail ms-4 cursor-pointer">+91 98257 18587</span>
            </div>
          </div>
        </div>

        <div className="address mt-5 grid justify-content-center">
          <div className="p-4  mt-2" style={{ background: '#2f436d', borderRadius: '20px' }}>
            <div className="d-flex align-items-center">
              <img src={location} alt="" />
              <span className="ms-3 footerAddressTitle">Surat</span>
            </div>
            <div className="mt-3 footerAddressText">
              <span>A 103-104, City Center Residency, Near Resham Bhavan, Lal Darvaja, Staion Road, Surat, Gujarat, India - 395003</span>
            </div>
          </div>
          <div className="p-4  mt-4" style={{ background: '#2f436d', borderRadius: '20px' }}>
            <div className="d-flex align-items-center">
              <img src={location} alt="" />
              <span className="ms-3 footerAddressTitle">Ahmedabad</span>
            </div>
            <div className="mt-3 footerAddressText">
              <span>312, Gala Hub, Near Basant Bahar Banglow, Gala Gymkhana Road, South Bopal, Ahmedabad - 380058</span>
            </div>
          </div>
          <div className="p-4  mt-4" style={{ background: '#2f436d', borderRadius: '20px' }}>
            <div className="d-flex align-items-center">
              <img src={location} alt="" />
              <span className="ms-3 footerAddressTitle">USA</span>
            </div>
            <div className="mt-3 footerAddressText">
              <span>9423 Mitchell Glen Drive Charlotte NC 28277</span>
            </div>
          </div>
        </div>
        <div className="section mt-5 flex-col flex lg:items-center">
          <div className="footerCompanyHeader">Company</div>
          <div className="mt-3">
            <div>
              <div
                className="p-2 hover:bg-[#2f436d] rounded-lg footerCompanyText cursor-pointer"
                onClick={() => {
                  handleTop();
                  navigate('/portfolio');
                }}
              >
                Portfolio
              </div>
              <div
                className="p-2 hover:bg-[#2f436d] rounded-lg footerCompanyText cursor-pointer"
                onClick={() => {
                  handleTop();
                  navigate('/about-us');
                }}
              >
                About
              </div>
              <div
                className="p-2 hover:bg-[#2f436d] rounded-lg footerCompanyText cursor-pointer"
                onClick={() => {
                  handleTop();
                  navigate('/contact-us');
                }}
              >
                Contact Us
              </div>
              <div
                className="p-2 hover:bg-[#2f436d] rounded-lg  footerCompanyText cursor-pointer"
                onClick={() => {
                  handleTop();
                  navigate('/career');
                }}
              >
                Career
              </div>
              <div
                className="p-2 hover:bg-[#2f436d] rounded-lg footerCompanyText cursor-pointer"
                onClick={() => {
                  handleTop();
                  navigate('/blogs');
                }}
              >
                Blogs
              </div>
              {/* <div className="p-2 hover:bg-[#2f436d] rounded-lg footerCompanyText cursor-pointer">Testimonials</div> */}
              <div
                className="p-2 hover:bg-[#2f436d] rounded-lg footerCompanyText cursor-pointer"
                onClick={() => {
                  handleTop();
                  navigate('/privacy-policy');
                }}
              >
                Privacy Policy
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl  py-4 md:py-8 flex sm:flex-row flex-col justify-between items-center bg-[#152C5B]">
        <div>
          <span className="footerCopyRight">© 2024 All Rights Reserved by Shivay Intelligence</span>
        </div>
        <div className="flex cursor-pointer gap-2 ">
          <a
            href="https://www.instagram.com/shivay_inteligence?igsh=MXFlejBsNnBleXB6OQ=="
            target="_blank"
            rel="noopener noreferrer"
            className="py-2 px-3 hover:bg-[#2f436d] rounded-lg"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://www.linkedin.com/company/100051585/admin/settings/" target="_blank" rel="noopener noreferrer" className="py-2 px-3 hover:bg-[#2f436d] rounded-lg">
            <img src={linkedin} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

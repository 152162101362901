import React from 'react';
import backGround from '../Images/contact-us-banner.png';
// import facebook from '../Images/Asset 26.png';
import backGround2 from '../Images/Contact_us_bg1.png';
import instagram from '../Images/Asset 27.png';
import linkedin from '../Images/Asset 28.png';
import linkedin1 from '../Images/Linked IN.png';
const ContactInformation = () => {
  return (
    <div
      className="p-4 contact-bg"
      style={{
        backgroundImage: `url(${backGround})`,
        width: '100%',
        backgroundSize: 'cover', // Ensures the image covers the entire div
        backgroundRepeat: 'no-repeat', // Prevents image from repeating
        backgroundPosition: 'center', // Centers the image//
        height: '100%',
      }}
    >
      <div className="container-xxl d-flex justify-content-between align-items-start">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-center align-items-center pt-2" style={{}}>
            <div className="contact-us-title align-self-end">Contact</div>
            <div className="contact-us-dot-1 ms-2"></div>
            <div className="contact-us-dot-2 ms-2"></div>
          </div>
          <div
            className="p-4 d-flex flex-column justify-content-center contact-us-information-container text-white"
            style={{
              backgroundImage: `url(${backGround2})`,
              width: '100%',
              backgroundSize: 'inherit', // Ensures the image covers the entire div
              backgroundRepeat: 'no-repeat', // Prevents image from repeating
              backgroundPosition: 'bottom right', // Centers the image
            }}
          >
            <div className="font-poppins text-[32px] font-[700] mb-4">Contact Information</div>
            <div className="mt-2 mb-2">
              <div className="font-open-sana font-[700] text-[18px] mb-3">SURAT</div>
              <div className="font-open-sana font-[400] text-[16px]">
                A 103-104, City Center Residency
                <br /> Near Resham Bhavan, Lal Darvaja,
                <br /> Staion Road, Surat, Gujarat,
                <br /> India - 395003
              </div>
            </div>
            <div className="mt-2 mb-2">
              <div className="font-open-sana font-[700] text-[18px] mb-3">AHMADABAD</div>
              <div className="font-open-sana font-[400] text-[16px]">
                312, Gala Hub,
                <br /> Gala Gymkhana Road, South Bopal,
                <br /> Ahmedabad, Gujarat, India - 380058
              </div>
            </div>
            <div className="mt-2 mb-2">
              <div className="font-open-sana font-[700] text-[18px] mb-3">USA</div>
              <div className="font-open-sana font-[400] text-[16px]">9423 Mitchell Glen Drive Charlotte NC 28277</div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
              <div className="d-flex justify-content-between align-items-center" style={{ width: '70%' }}>
                <div className="contact-us-follow-us">Follow us on</div>
                <img className="contact-us-linkedIn" src={linkedin1} alt="" />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
              <div className="d-flex gap-x-5 align-items-center" style={{ width: '50%' }}>
                {/* <img src={facebook} alt="" /> */}
                <a href="https://www.instagram.com/shivay_inteligence?igsh=MXFlejBsNnBleXB6OQ==" target="_blank" rel="noopener noreferrer">
                  <img src={instagram} alt="instagram" />
                </a>
                <a href="https://www.linkedin.com/company/100051585/admin/settings/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;

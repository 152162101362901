import React from 'react';
import backGround1 from '../Images/whatWeDoImage1.jpeg';
import backGround2 from '../Images/whatWeDoImage2.jpeg';
import { Button } from 'antd';

const WhatWeDo = () => {
  return (
    <div className="whatWeDoMainContainer">
      <div
        className="relative"
        style={{
          backgroundImage: `url(${backGround1})`,
          width: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '400px',
          // backgroundBlendMode: 'darken',
          // opacity: '0.5',
        }}
      >
        <div className="absolute bg-[#152C5B] top-0 bottom-0 left-0 right-0 opacity-50"></div>
        <div className="absolute top-0 bottom-0 left-0 right-0 container-xxl d-flex flex-column justify-content-center align-items-center p-4" style={{ height: '100%' }}>
          <div>
            <div className="whatWeDoTitle text-center">Why SHIVAY?</div>
            <div className="whatWeDoSubtitle text-center">
              What makes Shivay Intelligence’s Solutions
              <br /> different from other managed IT providers?`
            </div>
            <div className="d-flex justify-content-center">
              <Button type="text">
                <span className="whatWeDoButton text-decoration-underline">Learn More</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="relative"
        style={{
          backgroundImage: `url(${backGround2})`,
          width: '100%',
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundRepeat: 'no-repeat', // Prevents image from repeating
          backgroundPosition: 'center', // Centers the image
          height: '400px',
        }}
      >
        <div className="absolute bg-[#152C5B] top-0 bottom-0 left-0 right-0 opacity-50"></div>
        <div className="absolute top-0 bottom-0 left-0 right-0 container-xxl d-flex flex-column justify-content-center align-items-center p-4" style={{ height: '100%' }}>
          <div>
            <div className="whatWeDoTitle text-center">What We Do?</div>
            <div className="whatWeDoSubtitle text-center">
              What makes Shivay Intelligence’s Solutions
              <br /> different from other managed IT providers?
            </div>
            <div className="d-flex justify-content-center">
              <Button type="text">
                <span className="whatWeDoButton text-decoration-underline">Learn More</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;

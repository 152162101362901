import React from 'react';
// import { Button, Card, Row, Col } from 'antd';
import img1 from '../Images/serviceTech-1.png';
import img2 from '../Images/serviceTech-2.png';
import img3 from '../Images/serviceTech-3.png';
import img4 from '../Images/serviceTech-4.png';
import img5 from '../Images/serviceTech-5.png';
import img6 from '../Images/serviceTech-6.png';

const ServiceTechStack = () => {
  const data = [
    {
      id: 1,
      img: img1,
      title: 'Transforming Futures with AI/ML Innovation',
      text: 'Unlock smart, self-learning systems customized for your business.',
      buttonText: 'Ignite Intelligence →',
    },

    {
      id: 2,
      img: img2,
      title: 'Data Management: Your Insights Engine',
      text: 'Harness the power of data to drive decision-making and strategic growth.',
      buttonText: 'Master Your Data →',
    },
    {
      id: 3,
      img: img3,
      title: 'Elevate to the Cloud with Confidence',
      text: 'Leverage our expertise to navigate your cloud journey for scalable success.',
      buttonText: 'Ascend to Cloud →',
    },
    {
      id: 4,
      img: img4,
      title: 'Crafting Web Experiences That Resonate',
      text: 'Build the bridge between user satisfaction and technological excellence.',
      buttonText: 'Develop Presence →',
    },
    {
      id: 5,
      img: img5,
      title: 'Crafting Web Experiences That Resonate',
      text: 'Build the bridge between user satisfaction and technological excellence.',
      buttonText: 'Develop Presence →',
    },

    {
      id: 6,
      img: img6,
      title: 'Automated Marketing: Engage at Scale',
      text: 'Create personalized customer journeys with our automated marketing solutions.',
      buttonText: 'Automate Now →',
    },
  ];
  return (
    <div style={{ backgroundColor: '#F6F7F8' }} className="py-5 px-3">
      <div className="w-full md:w-4/5 m-auto">
        <div className="flex flex-wrap justify-center md:justify-start">
          {data?.map((item) => {
            return (
              <div key={item.id} className="flex flex-col px-3 w-full sm:w-1/2 md:w-1/2 lg:w-1/3 md:min-h-[320px] min-h-[250px] sm:min-h-[280px] py-2">
                <div className="h-full flex flex-col items-center justify-center">
                  <img src={item.img} alt="" className="w-12 h-12 object-contain" />
                  <div className="md:mt-4 mt-2   font-bold md:text-[24px] text-[20px] font-poppins text-center">{item.title}</div>
                  <div className="md:mt-4 mt-2 md:text-[18px] text-[16px] text-center font-open-sana  font-[700] leading-7">{item.text}</div>
                  {/* <button className="mt-4 px-6 py-2 bg-[#152c5b] text-white rounded-lg font-open-sana">{item.buttonText}</button> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServiceTechStack;

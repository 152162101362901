import React, { useState } from 'react';
import { Form, Input, Checkbox, Button, notification, Tooltip } from 'antd';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import emailImage from '../Images/Component 1.png';
import marker from '../Images/shivay-logo.png';
import { postEmails } from '../action/Functions';
import backGround from '../Images/Map_BG.png';
const ContactUsFormContainer = () => {
  const [api, contextHolder] = notification.useNotification();

  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBKZZ-MT18p1korxyC2FB95B0kYjpPe6_M',
    //api key
  });

  const [details, setDetails] = useState({
    email: '',
    name: '',
    phone: '',
    isAgree: false,
    message: '',
  });

  const onFinish = (values) => {
    setLoading(true);
    postEmails(values).then(() => {
      api['success']({
        message: 'Send Mail Successful',
      });
      setLoading(false);
      setDetails({
        email: '',
        name: '',
        phone: '',
        isAgree: false,
        message: '',
      });
      form.resetFields();
    });
  };

  const handleChange = (e) => {
    const { id, value, checked } = e.target;

    if (id == 'isAgree') {
      setDetails((pre) => ({
        ...pre,
        isAgree: checked,
      }));
    } else {
      setDetails((pre) => ({
        ...pre,
        [id]: value,
      }));
    }
  };
  return (
    <>
      {contextHolder}
      <div
        className="pt-5  pb-5"
        style={{
          backgroundImage: `url(${backGround})`,
          width: '100%',
          height: '100%', // Adjust the height as needed
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundRepeat: 'no-repeat', // Prevents image from repeating
          backgroundPosition: 'bottom', // Centers the image
          backgroundColor: '#F7F9FF',
        }}
      >
        <div className="mt-4 mb-4">
          <div className="container-xxl contact-us-form-main-container">
            <div className="contact-us-email-container">
              <div>
                <Tooltip placement="top" title={'hr@shivayintelligence.com'}>
                  <img src={emailImage} alt="" />
                </Tooltip>
              </div>
            </div>
            <div className="p-4 contact-us-form-input-container  bg-white rounded-lg shadow-lg shadow-[rgba(0,0,0,0.66)]">
              <div className="contact-us-form-title text-center">We ‘d love to help</div>
              <div className="contact-us-form-subtitle text-center mt-1">Empower Your Success – Connect with Us to Elevate Your Expertise</div>
              <Form className="mt-4" name="inquiry" onFinish={onFinish} onChange={handleChange} layout="vertical" form={form}>
                <Form.Item name="name" className="border-b-2 border-slate-600 " rules={[{ required: true, message: 'Please input your name!' }]}>
                  <Input placeholder="Name *" className="inputStyle" variant="borderless" id="name" value={details.name} />
                </Form.Item>

                <Form.Item
                  name="email"
                  className="border-b-2 border-slate-600 "
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                    {
                      type: 'email',
                      message: 'The input is not a valid email!',
                    },
                  ]}
                >
                  <Input id="email" className="inputStyle" placeholder="E-mail ID *" variant="borderless" value={details.email} />
                </Form.Item>
                <Form.Item
                  name="phone"
                  className="border-b-2 border-slate-600 "
                  rules={[
                    {
                      required: true,
                      message: 'Please input your phone number!',
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: 'The phone number must be 10 digits!',
                    },
                  ]}
                >
                  <Input id="phone" className="inputStyle" placeholder="Phone *" variant="borderless" value={details.phone} />
                </Form.Item>

                <Form.Item
                  name="message"
                  rules={[{ required: true, message: 'Please input your message!' }]}
                  style={{ borderRadius: '10px', boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.26)', height: '200px' }}
                >
                  <Input.TextArea
                    name="id"
                    placeholder="Message *"
                    className="inputStyle"
                    variant="borderless"
                    style={{ height: '200px', resize: 'none' }}
                    value={details.message}
                  />
                </Form.Item>
                <div className="contact-us-form-below-text mb-4">We take privacy very seriously and will only use your information to send E-Mails.</div>
                <Form.Item
                  name="isAgree"
                  valuePropName="checked"
                  rules={[{ validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement'))) }]}
                >
                  <Checkbox name="id" checked={details.isAgree}>
                    <div className="contact-us-i-agree">
                      I agree with the <span style={{ color: '#314ED9' }}>Privacy Policy</span>
                    </div>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <div>
                    <Button type="text" disabled={isLoading} className="hover:!bg-[#314ed9] bg-[#152c5b] text-white  fw-bolder" style={{ height: 50, width: '100%' }} htmlType="submit">
                      {isLoading ? 'Loading ...' : 'Send Inquiry'}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div className="ps-1 pt-4 pb-4 pe-4 contact-us-form-map-container">
              {isLoaded ? (
                <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={{ lat: 23.0225, lng: 72.5714 }} zoom={7}>
                  <MarkerF icon={marker} position={{ lat: 23.0312422, lng: 72.4598806 }} draggable={false} />
                  <MarkerF icon={marker} position={{ lat: 21.207129, lng: 72.837206 }} draggable={false} />
                </GoogleMap>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsFormContainer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allBlogs: [],
  blogsBySK: [],
  allJobs: [],
  jobsBySK: [],
};

export const getDataSlice = createSlice({
  name: 'getData',
  initialState,
  reducers: {
    resetactionGetJobsBySk: (state) => {
      state.jobsBySK = [];
    },
    actionGetAllJobs: (state, action) => {
      state.allJobs = action.payload ?? [];
    },
    actionGetAllBlogs: (state, action) => {
      state.allBlogs = action.payload ?? [];
    },
    actionGetJobsBySk: (state, action) => {
      state.jobsBySK = action.payload ?? [];
    },
    actionGetBlogsBySK: (state, action) => {
      state.blogsBySK = action.payload ?? [];
    },
  },
});

export const { actionGetAllJobs, actionGetAllBlogs, actionGetJobsBySk, actionGetBlogsBySK, resetactionGetJobsBySk } = getDataSlice.actions;

export default getDataSlice.reducer;

import React from 'react';
import { Button } from 'antd';
import backgroundImage from '../Images/enpowering-image.png';
import backGround from '../Images/enpowering-image-bg.png';
import { useNavigate } from 'react-router-dom';

const DigitalFuture = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact-us');
  };
  return (
    <div className="relative" style={{ paddingTop: '80px', paddingBottom: '80px' }}>
      <div className="grid container-xxl lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1  gap-5 mb-[20px]">
        <div className="grid content-center">
          <div className="grid content-center">
            <img src={backgroundImage} alt="" style={{ objectFit: 'contain' }} className="h-[350px] lg:h-[480px] md:h-[600px] mx-auto" />
          </div>
        </div>
        <div className="grid content-center font-poppins font-[900]">
          <div className="mb-[15px] text-[18px] font-open-sana font-[700]">We will help you in</div>
          <div className="text-[44px] text-left mb-[20px]">
            Empowering Your
            <br />
            <span style={{ color: '#314ED9' }}>Digital Future</span>
          </div>
          <p className="text-[18px] font-open-sana font-[400] text-left mb-[20px]">
            At Shivay Intelligence, we specialize in AI and ML innovation, scalable serverless apps, efficient DevOps, secure cloud solutions, engaging website development, and
            insightful data science. Your pathway to a transformed, tech-empowered business.
          </p>
          <div className="">
            <Button type="text" className="hover:!bg-[#314ed9] bg-[#152c5b] text-white text-base  fw-bolder" style={{ height: 50 }} onClick={handleClick}>
              Get Consultation
            </Button>
          </div>
        </div>
      </div>

      {/* <div className="absolute right-0 bottom-0 w-1/4">
        <img src={backGround} alt="image" />
      </div> */}
    </div>
  );
};

export default DigitalFuture;

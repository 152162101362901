import axios from 'axios';
import { baseUrl } from './apiSheet';

export const getDetails = async (details) => {
  const { pk, sk } = details;
  try {
    const response = await axios.get(`${baseUrl}dynamo-resource/?pk=${pk}&sk=${sk}`);
    return response;
  } catch (error) {
    console.log('error', error);
  }
};

export const postEmails = async (details) => {
  try {
    const response = await axios.post(`${baseUrl}contact-us`, details, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    console.log('error', error);
  }
};

import { configureStore } from '@reduxjs/toolkit';
import getDataReducer from '../action/Slicer/getDataSlice';
import blogSlicer from './Slicer/blogSlicer';

export const store = configureStore({
  reducer: {
    getdata: getDataReducer,
    allBlogsReducer: blogSlicer,
  },
});
